import React from "react";
import { Form as StyledForm, Wrapper, Paper } from "./Form.styled";
import Error from "../Error/Error";

import { useFormContext } from "../Context/FormContext";
function Form({ children, onSubmit }) {
  const { isError } = useFormContext();

  return (
    <>
      <Paper>
        {isError ? (
          <Error
            text={
              "Odeslaní poptávky se nezdařilo, kontaktuje nás prosím napřímo."
            }
            backTo={""}
          />
        ) : (
          ""
        )}

        <Wrapper>
          <StyledForm onSubmit={onSubmit}>{children}</StyledForm>
        </Wrapper>
      </Paper>
    </>
  );
}
export default Form;
