import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";
import { isPersistedState } from "../http-common";

let filters = {
  rozsah: "",
  membrana: "",
  page : 0
}
function useProductsFetch() {
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);


  const [rozsah, setRozsah] = useState(filters.rozsah);
  const [membrana, setMembrana] = useState(filters.membrana);

  const [page, setPage] = useState(filters.page);



  async function getProducts(

    rozsah = "",
    membrana = "",

    page = 0
  ) {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await ProductsDataService.getTlakoveSnimace(
 
        rozsah,
        membrana,
  
        page
      );
  

      let totalPages;

      if (
        response.data.totalNumProducts % response.data.entries_per_page ===
        0
      ) {
        totalPages =
          parseInt(
            response.data.totalNumProducts / response.data.entries_per_page,
            10
          ) - 1;
      } else {
        totalPages = parseInt(
          response.data.totalNumProducts / response.data.entries_per_page,
          10
        );
      }

      setProducts((prev) => ({
        ...response.data,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : [...response.data.products],
        totalPages,
      }));
    } catch (err) {
      console.error(`Unable to fetch data in useHomePageFetch ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
  
    
  if(filters.rozsah === rozsah && filters.membrana === membrana && filters.page === page){
    const sessionState = isPersistedState("TlakoveSnimace")
    if(sessionState){
      setProducts(sessionState)

      return
    }    }
    getProducts(rozsah, membrana, page)
 
  }, [rozsah, membrana, page]);

//session storage
  useEffect(()=> {
     sessionStorage.setItem("TlakoveSnimace",JSON.stringify(products))
    
     filters = {rozsah, membrana, page}
  
  
     
  }, [products, rozsah, membrana, page ])



  return {
    products,
    isLoading,
    isError,

    setRozsah,
    setMembrana,

    setPage,
    page,

    rozsah,
    membrana,

  };
}

export default useProductsFetch;
