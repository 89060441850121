import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";
import { isPersistedState } from "../http-common";

let filters = {

  category: "",
  pripojeni: "",

  page : 0
}
function useProductsFetch() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [category, setCategory] = useState(filters.category);
  const [pripojeni, setPripojeni] = useState(filters.pripojeni);

  const [page, setPage] = useState(filters.page);



  async function getProducts(
    category = "",
    pripojeni = "",

    page = 0
  ) {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await ProductsDataService.getTeplotniSnimace(
        category,
        pripojeni,
 
        page
      );
  

      let totalPages;

      if (
        response.data.totalNumProducts % response.data.entries_per_page ===
        0
      ) {
        totalPages =
          parseInt(
            response.data.totalNumProducts / response.data.entries_per_page,
            10
          ) - 1;
      } else {
        totalPages = parseInt(
          response.data.totalNumProducts / response.data.entries_per_page,
          10
        );
      }

      setProducts((prev) => ({
        ...response.data,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : [...response.data.products],
        totalPages,
      }));
    } catch (err) {
      console.error(`Unable to fetch data in useTeplotniSnimaceFetch ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
  
    
  if(filters.category === category && filters.pripojeni === pripojeni && filters.page === page){
    const sessionState = isPersistedState("TeplotniSnimace")
    if(sessionState){
      setProducts(sessionState)

      return
    }    }
    getProducts(category, pripojeni, page)
 
  }, [category, pripojeni, page]);

//session storage
  useEffect(()=> {
     sessionStorage.setItem("TeplotniSnimace",JSON.stringify(products))
    
     filters = {category, pripojeni, page}
  
  
     
  }, [products, category, pripojeni, page ])



  return {
    products,
    isLoading,
    isError,
    setCategory,
    setPripojeni,
    setPage,
    page,
    category,
    pripojeni,
  
  };
}

export default useProductsFetch;
