import styled from "styled-components";
export const MapWrapper = styled.div`
width: 100%;
height: 50vh;
`
export const Wrapper = styled.div`
  max-width: var(maxWidth);
  margin: 0px auto;
  padding: 0 20px;

  h1 {
    color: var(--medGrey);
    padding: 20px 0px 0px 0px;
    @media screen and (max-width: 768px) {
      font-size: var(--fontBig);
    }
  }
  iframe {
    padding: 20px 0px;
    width: 100%;
    height: 500px;
    border: none;
  }
`;
export const Title = styled.h2`
  font-size: var(--fontSuperBig);
  font-weight: bold;
  margin: 0 auto;
  display: block;
  padding: 20px 0px;
  text-align: center;
  color: hsla(200, 100%, 10%, 0.8);
  @media screen and (max-width: 720px) {
    font-size: var(--fontMed);
  }
`;
export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
