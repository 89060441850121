import React, { useContext } from "react";
import useTlakoveSnimaceFetch from "../../hooks/useTlakoveSnimaceFetch";

const TlakoveSnimaceContext = React.createContext();

export function useTlakoveSnimaceContext() {
  return useContext(TlakoveSnimaceContext);
}

export default function TlakoveSnimaceProvider({ children }) {
  const productsData = useTlakoveSnimaceFetch();

  return (
    <TlakoveSnimaceContext.Provider value={productsData}>
      {children}
    </TlakoveSnimaceContext.Provider>
  );
}
