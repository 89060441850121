import {useEffect} from "react"
import {useLocation} from "react-router-dom"
function usePageScrolled(
  targetElemId,
  showOnPosition = "0px",
  hideBy = "-100px"
) {

  const {pathname} = useLocation()

  useEffect(()=>{
    window.scrollTo(0,0)
  }, [pathname])

 
 let prevScrollpos = window.scrollY

  window.onscroll = function () {
    
    let currentScrollPos = window.scrollY;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById(targetElemId).style.top = showOnPosition;
    } else {
      document.getElementById(targetElemId).style.top = hideBy;
    }

      prevScrollpos = currentScrollPos;



    
  };
}
export default usePageScrolled;
