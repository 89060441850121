import styled from "styled-components";

export const BoxTitle = styled.h3`
  color: var(--medGrey);
  padding: 0 20px;
  border-bottom: ridge;
  border-width: 1px;
  border-color: hsla(200, 100%, 10%, 0.2);
`;
export const Wrapper = styled.div`
  background: var(--white);
  border-style: solid;
  border-color: hsla(200, 100%, 10%, 0.5);
  border-width: 2px;
  p {
    padding: 0px 20px;
    color: #000;

    a {
      :link {
        text-decoration: none;
        color: hsla(200, 100%, 10%, 1);
      }
      :hover {
        color: hsla(200, 100%, 10%, 0.5);
      }
    }
  }
`;

export const Content = styled.div`
  text-align: left;

  span {
    font-weight: bolder;
  }
`;
