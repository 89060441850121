import { BoxTitle, Wrapper, Content } from "./InfoBox.styled";

function InfoBox(props) {
  return (
    <>
      <Wrapper>
        <BoxTitle>{props.title}</BoxTitle>
        <Content>
          <p>
            {" "}
            <span>{props.idTextUp}</span>
            {props.textUp}
          </p>
          <p>
            <span>{props.idTextMid}</span>
            {props.textMid}
          </p>
          {props.textLow ? (
            <p>
              <span>{props.idTextLow}</span>
              {props.textLow}
            </p>
          ) : (
            ""
          )}
        </Content>
      </Wrapper>
    </>
  );
}

export default InfoBox;
