import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";

function useProductFetchById(id, productCategory) {
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isNotFound, setIsNotFound] = useState(false);

  async function getProductById(id, productCategory) {
    if (!productCategory) return;
    try {
      setIsLoading(true);

      const response = await ProductsDataService.getByID(id, productCategory);
      setProduct(response.data);
    } catch (err) {
      if (err.response.status === 404) {
        setIsNotFound(true);
        setIsLoading(false);
        return;
      }

      console.error(`Cannot fetch productById ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    window.gtag("event", "product_detail_view")
    getProductById(id, productCategory);
   
  }, [id, productCategory]);

  return {
    product,
    isLoading,
    isError,

    productCategory,
    isNotFound,
  };
}
export default useProductFetchById;
