import { useTeplotniSnimaceContext } from "../Context/TeplotniSnimaceContext";

function useTeplotniSnimaceSearchBar() {
  const { setCategory, setPripojeni, setPage, category, pripojeni } =
    useTeplotniSnimaceContext();
  function reset() {
    setCategory("");
    setPripojeni("");
    setPage(0);
    return;
  }
  const searchBarData = [
    {
      handler: setCategory,
      value: ["Teplotní snímač odporový", "Teplotní snímač termoelektrický"],
      text: ["Termistor", "Termočlánek"],
      name: "category",
      title: "Čidlo",
      state: category
    },
    {
      handler: setPripojeni,
      value: ["S jímkou", "Do jímky", "Ochranná trubka"],
      text: ["S jímkou", "Do jímky", "Ochranná trubka"],
      name: "pripojeni",
      title: "Připojení",
      state: pripojeni
    },
  ];
  return { searchBarData, reset };
}
export default useTeplotniSnimaceSearchBar;
