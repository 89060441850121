import React from "react";
import { Wrapper, Image, Content } from "./ProductThumb.styled";

import { StyledLink } from "../Header/Header.styles";
function ProductThumb({ itemName, imageSrc, description }) {
  return (
    <Wrapper>
      <StyledLink to={`${description.descriptionOne}`}>
        <Content>
          <Image src={imageSrc} alt="Product image" />
        </Content>
        <h3>{itemName}</h3>
        <p>{description.descriptionOne}</p>
        <p>{description.descriptionTwo}</p>
        <p>{description.descriptionThree}</p>
      </StyledLink>
    </Wrapper>
  );
}
export default ProductThumb;
