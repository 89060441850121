import styled from "styled-components";

export const Wrapper = styled.section`
margin: 0 auto;

   
    h1 {
        color: var(--medGrey);
        font-size: var(--fontSuperBig);
      
}
      
      font-size: var(--fontMed);
    

    

`;
export const Content = styled.div`
max-width: ${props => props.maxWidth ? props.maxWidth : "none"};
margin: ${props => props.margin ? props.margin : "0px"};
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      ${(props) => props.min || "300px"},
      ${(props) => props.size || "max-content"}
    )
  );
  grid-gap: 1rem;
  
`;
