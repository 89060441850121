import { usePrislusenstviContext } from "../Context/PrislusenstviContext";

function usePrislusenstviSearchBar() {
  const { setTyp, setPage, setCategory, typ, category } = usePrislusenstviContext();
  function reset() {
    setTyp("");
    setCategory("")
    setPage(0);
    return;
  }
  const searchBarData = [
   {
      handler: setTyp,
      value: ["tlak", "teplota"],
      text: ["Tlak", "Teplota"],
      name: "typ",
      title: "Účel",
      state: typ
    },

    {
      handler: setCategory,
      value: ["Kohout", "Ventil", "Smyčka", "Těsnění", "Přechodka", "Jímka"],
      text: ["Kohouty", "Ventily", "Smyčky", "Těsnění", "Přechodky", "Jímky"],
      name: "category",
      title: "Produkt",
      state: category
    },
 
  ];
  return { searchBarData, reset };
}
export default usePrislusenstviSearchBar;
