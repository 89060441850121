import styled from "styled-components";
export const Wrapper = styled.div`
padding: 0 0 20px 0;
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }

  table td,
  table th {
    border: 1px solid #ddd;
    padding: 0.8em;
  }

  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  table tr:hover {
    background-color: #ddd;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;

    background-color: #023;
    color: white;
  }
  @media screen and (max-width: 780px) {
      display: none;
    }
`;
