import React from "react";
import { Wrapper, Content } from "../Grid/Grid.styles";
function Grid(props) {
  return (
    <Wrapper>
      <h1>{props.header ? props.header : ""}</h1>
    
      <Content size={props.size} min = {props.min} maxWidth ={props.maxWidth} margin ={props.margin}>{props.content} </Content>
    </Wrapper>
  );
}
export default Grid;
