import styled from "styled-components";


export const Wrapper = styled.div`
display: ${props => props.isVisible ? "block" : "none"};
z-index: 1000000;
width: 100%;
position: fixed;
display: flex;
align-items: center;
justify-content: center;

background-color: rgba(219, 240, 231, 0.95);


  bottom: 0;
  left: 0;


`
export const Content = styled.div`



padding: 1rem 1em;
max-width: 60em;
margin: 0 auto;

line-height: 24px;
font-size: var(--fontSmall);
p{
    color: #000;
 
}  
.refference{
    :hover{
  
      opacity: 0.8;
    }
  }
`
export const Buttons = styled.div `
display: flex;
width: 100%;
justify-content: flex-end;
flex-wrap: wrap-reverse;

.button {
  min-width: 10em;


  border-style: none;
margin: 0 10px;
  color: #fff;
  cursor: pointer;

  font-size: var(--fontSmall);
  font-weight: 500;

  line-height: 1.5em;
  list-style: none;

  outline: none;
  padding: 10px 20px;

  text-align: center;
  text-decoration: none;
  :hover {
    background-color: hsla(200, 100%, 10%, 0.5);
  }
 
  
  @media screen and (max-width: 592px) {
            width: 100%;
            margin: 5px 0;
        }
}
 .acceptAll{
    background-color: rgba(49, 176, 108, 1);
    background-color: #023;
  }
  .declineAll{
    border: 2px solid #3A4649;
    color: #023;
  }
  .analyticsOnly{
    border: 2px solid #3A4649;
    color: #023;
  }

`
