import styled from "styled-components";
export const Wrapper = styled.div`
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 41%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    url(${(props) => props.image}), var(--darkGrey);
  background-size: 100%, cover;

  background-position: center;
  position: relative;
  animation: animateHeroImage 1s;
  height: 80vh;

  @keyframes animateHeroImage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export const Content = styled.div`
  padding: 20px;
  max-width: var(--maxWidth);
  margin: 0px auto;
`;
export const Text = styled.header`
  z-index: 1;
  max-width: 24em;
  position: absolute;
  bottom: 30px;
line-height: 1.2;
  margin-right: 20px;
  min-height: 100px;
  color: var(--white);
  text-align: justify;
  h1 {
    font-size: 2.5rem;

    @media screen and (max-width: 720px) {
      font-size: var(--fontSuperBig);
    }
  }
  p {
    font-size: var(--fontMed);

    @media screen and (max-width: 720px) {
      font-size: var(--fontSmall);
    }
  }

  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
  @media screen and (min-width: 2000px) {
 max-width: 38em;
  }
`;
export const Certifikat = styled.img`
  z-index: 10;

    position: absolute;
    right: 3.35rem;
    height: 200px;
  animation: animateHeroImage 1s;


  @keyframes animateHeroImage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: 670px) {
    right: 1.2em;
    height: 150px;
  }
  @media screen and (min-width: 2000px) {
    height: 250px;
  }
`