import React from "react";
import TeplotniSnimaceProvider from "./Context/TeplotniSnimaceContext";
import TeplotniSnimace from "./TeplotniSnimace/TeplotniSnimace";


function TeplotniSnimacePage() {

 document.title = "Teplotní snímače | MANOTHERM CZ"
  
  return (
    <>
      <TeplotniSnimaceProvider>
        <TeplotniSnimace />
      </TeplotniSnimaceProvider>
    </>
  );
}
export default TeplotniSnimacePage;
