import React, { useContext } from "react";
import useFormPost from "../../hooks/useFormPost";

const FormContext = React.createContext();

export function useFormContext() {
  return useContext(FormContext);
}

export default function FormProvider({ children }) {
  const formPostData = useFormPost();

  return (
    <FormContext.Provider value={formPostData}>{children}</FormContext.Provider>
  );
}
