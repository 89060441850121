import { useTlakoveSnimaceContext } from "../Context/TlakoveSnimaceContext";

function useTlakoveSnimaceSearchBar() {
  const { setRozsah, setMembrana, setPage, rozsah, membrana } =
    useTlakoveSnimaceContext();
  function reset() {
    setRozsah("");
    setMembrana("");
    setPage(0);
    return;
  }
  const searchBarData = [
 
    {
      handler: setRozsah,
      value: ["podtlak", "nizkotlak", "stredotlak", "stredotlak2", "vysokotlak", "vysokotlak2"],
      text: ["< 0 kPa / 0 bar ", "< 41 kPa / 410 mbar", "< 10,1 MPa / 101 bar","< 40,1 MPa / 401 bar", "< 40,1 MPa / 401 bar", "> 40,1 MPa / 401 bar"],
      name: "rozsah",
      title: "Rozsah",
      state: rozsah
    },
    {
      handler: setMembrana,
      value: [
        "Keramická",
        "Nerezová ocel"
        
      ],
      text: [
        "Keramická",
        "Nerezová ocel"
      ],
      name: "membrana",
      title: "Membrána",
      state: membrana
    },
  ];
  return { searchBarData, reset };
}
export default useTlakoveSnimaceSearchBar;
