import React, { useState } from "react";

import Form from "../Form/Form";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import InputField from "../InputField/InputField";
import Alert from "../Alert/Alert";
import { useFormContext } from "../Context/FormContext";
import { StyledLink } from "../Header/Header.styles";
import {
  Wrapper,
  SpanNotification,
  AllertWrapper,
  Confirmation,
  GDPRWrapper,
} from "./InquiryForm.styled";

function InquiryForm() {
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isGDPR, setIsGDPR] = useState(false);
  const {
    isSubmited,
    isError,
    isShowModal,
    isShowModalInvalidInput,
    isLoading,
    alertInfo,
  } = useFormContext();

  function handleClick() {
    setIsSubmitButtonClicked(true);
  }
  function handleGDPR(e) {
    setIsGDPR((prev) => !prev);
  }

  return (
    <>
      <Wrapper>
        <h1>Rychlá poptávka</h1>
        {isSubmited && !isError ? (
          <Confirmation>
            Děkujeme Vám, Vaše poptávka byla úspěšné odeslána. V blízké době Vám
            zašleme odpověď na uvedený kontakt.
          </Confirmation>
        ) : (
          <Form>
            <InputField
              placeholderText="Jméno*"
              id="jmeno"
              type="text"
              pattern="^[a-žA-Ž ]+$"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              required
              styleTyp="text"
            />

            <InputField
              placeholderText="Firma"
              id="firma"
              type="text"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              styleTyp="text"
            />

            <InputField
              placeholderText="IČO"
              id="ico"
              type="text"
              pattern="[0-9]*"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              styleTyp="text"
            />

            <InputField
              placeholderText="Email*"
              id="email"
              type="email"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              styleTyp="text"
              required
            />

            <InputField
              placeholderText="Tel. číslo"
              id="tel_cislo"
              type="tel"
              pattern="^[+]?[()/0-9. -]{9,}$"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              styleTyp="text"
            />

            <InputField
              placeholderText="Text*"
              id="text"
              type="text"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
              required
              styleTyp="textarea"
            />

            <InputField
              id="attachement"
              type="file"
              styleTyp="file"
              isSubmitButtonClicked={isSubmitButtonClicked}
              setIsSubmitButtonClicked={setIsSubmitButtonClicked}
            />

            {isShowModal || isShowModalInvalidInput ? (
              <AllertWrapper>
                {isShowModal ? (
                  <SpanNotification position={"left"}>
                    * Povinná pole
                  </SpanNotification>
                ) : (
                  ""
                )}
                {isShowModalInvalidInput ? (
                  <SpanNotification position={"right"}>
                    Zadejte správný formát pole
                  </SpanNotification>
                ) : (
                  ""
                )}
              </AllertWrapper>
            ) : (
              ""
            )}
            <Alert
              alertText={alertInfo.alertText}
              isDisplayed={alertInfo.isAlertDisplayed}
            />
            {isLoading ? <Spinner /> : ""}
            <GDPRWrapper>
              <input
                type="checkbox"
                id="gdpr"
                onClick={handleGDPR}
                value={isGDPR}
              />
              <label htmlFor="gdpr">
                Souhlasím se zpracováním uvedených <StyledLink color="#000" to={"/ochrana-udaju"}><span style={{opacity: "0.8"}}>údajů</span></StyledLink> za účelem vytvoření
                obchodní nabídky.{" "}
                
              </label>
            </GDPRWrapper>
            <Button
              text="Odeslat"
              onClick={handleClick}
              disabled={isError || !isGDPR}
            />
          </Form>
        )}
      </Wrapper>
    </>
  );
}
export default InquiryForm;
