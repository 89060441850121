import styled from "styled-components";

export const Wrapper = styled.div`
display: ${props => props.isDisplayed ? "flex" : "none" };
padding: 20px;
  background-color: yellow;
  color: #000;
  margin-bottom: 15px;

  opacity: 1;
  z-index: 1000;
  position: absolute;
  left: 0;
  right:0;
  top: 4em;
  margin-left: auto; 
  margin-right: auto; 
  width: 20em; /* Need a specific value to work */
align-items: center;
justify-content: center;
font-size: var(--fontMed);
border-radius: 16px;
animation: animate 5s cubic-bezier(0, 0, 1, 0.62);
 

  @keyframes animate {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }


@media screen and (max-width: 493px) {
    width: 15em;
  }
`
