import styled from "styled-components";

export const Form = styled.form`
  margin: 0;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Wrapper = styled.div`
  margin: 0px auto;
  padding: 20px 40px;
  max-width: var(--maxWidth);
  font-size: var(--fontSmall);
`;
export const Paper = styled.div`
  position: relative;
  background: #fff;
  max-width: var(--maxWidth);
  margin: 0px auto;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.4);
`;
