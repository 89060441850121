import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Styles
import { GlobalStyle } from "./GlobalStyle";

//Components
import HomePage from "./components/homePage";
import Products from "./components/Products";
import ContactPage from "./components/ContactPage";
import About from "./components/About";
import Inquiry from "./components/Inquiry";
import Product from "./components/product";
import ManometryPage from "./components/ManometryPage";
import TeplomeryPage from "./components/TeplomeryPage";
import TlakoveSnimacePage from "./components/TlakoveSnimacePage";
import TeplotniSnimacePage from "./components/TeplotniSnimacePage";
import NotFound from "./components/NotFound";
import Header from "./components/Header/Header";
import PrislusenstviPage from "./components/PrislusenstviPage";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookieBar from "./components/CookieBar/CookieBar";


function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Header />
        <CookieBar />
        <main id="main">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/produkty" element={<Products />} />
            <Route path="/produkty/manometry" element={<ManometryPage />} />
            <Route path="/produkty/teplomery" element={<TeplomeryPage />} />
            <Route path="/produkty/prislusenstvi" element={<PrislusenstviPage />}/>
            <Route path="/produkty/teplotniSnimace" element={<TeplotniSnimacePage />} />
            <Route path="/produkty/tlakoveSnimace" element={<TlakoveSnimacePage />} />
            <Route path="/produkty/:productCategory/:id" element={<Product />} />
            <Route path="/kontakt" element={<ContactPage />} />
            <Route path="/o-nas" element={<About />} />
            <Route path="/poptavka" element={<Inquiry />} />
            <Route path="/ochrana-udaju" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </>
  );
}

export default App;
