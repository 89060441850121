import React from "react";
import TlakoveSnimaceProvider from "./Context/TlakoveSnimaceContext";
import TlakoveSnimace from "./TlakoveSnimace/TlakoveSnimace";


function TlakoveSnimacePage() {

 document.title = "Tlakové snímače | MANOTHERM CZ"
  
  return (
    <>
      <TlakoveSnimaceProvider>
        <TlakoveSnimace />
      </TlakoveSnimaceProvider>
    </>
  );
}
export default TlakoveSnimacePage;
