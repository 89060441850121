import {jsonData, form} from "../http-common";

class ProductsDataService {
  getByID(id, product) {
    return jsonData.get(`/${product}/${id}`);
  }
  getProducts(velikost, rozsah, pripojeni, typ, category, page = 0) {
    let query = {
      velikost: "",
      rozsah: "",
      pripojeni: "",
      typ: "",
      category: ""
    };
    if (velikost) {
      query.velikost = `&&velikost=${velikost}`;
    }
    if (rozsah) {
      query.rozsah = `&&rozsah=${rozsah}`;
    }
    if (pripojeni) {
      query.pripojeni = `&&pripojeni=${pripojeni}`;
    }
    if (typ) {
      query.typ = `&&type=${typ}`;
    }
    if (category) {
      query.category = `&&category=${category}`;
    }
   
    query = query.velikost + query.rozsah + query.pripojeni + query.typ + query.category;


    return jsonData.get(`/manometry/?page=${page}${query}`);
  }
  getThermomethers(prumer, typ, pripojeni, category, page = 0) {

    let query = {
      typ: "",
      prumer: "",
      pripojeni: "",
      category: ""
    };
    
    if (typ) {
      query.typ = `&&type=${typ}`;
    }
    if (prumer) {
      query.prumer = `&&prumer=${prumer}`;
    }
    if (pripojeni) {
      query.pripojeni = `&&pripojeni=${pripojeni}`;
    }
    if (category) {
      query.category = `&&category=${category}`;
    }
    query = query.typ + query.prumer + query.pripojeni + query.category;

    return jsonData.get(`/teplomery/?page=${page}${query}`);
  }
  getPrislusenstvi(typ, category, page = 0) {
    let query = {
      typ: "",
      category: "",
    };
    if (typ) {
      query.typ = `&&type=${typ}`;
    }
    if (category) {
      query.category = `&&category=${category}`;
    }
    query = query.typ + query.category;

    return jsonData.get(`/prislusenstvi/?page=${page}${query}`);
  }
  getTeplotniSnimace(category, pripojeni, page = 0) {

    let query = {
      category: "",
      pripojeni: "",
  
    };
 
    if (category) {
      query.category = `&&kategorie=${category}`;
    }
    if (pripojeni) {
      query.pripojeni = `&&procesniPripojeni=${pripojeni}`;
    }
  

    query = query.category + query.pripojeni;


    return jsonData.get(`/teplotniSnimace/?page=${page}${query}`);
  }
  getTlakoveSnimace(rozsah, membrana, page = 0) {
    let query = {
      rozsah: "",
      membrana: "",
     
    };
 
    if (rozsah) {
      query.rozsah = `&&rozsah=${rozsah}`;
    }
    if (membrana) {
      query.membrana = `&&membrana=${membrana}`;
    }
   

    query = query.rozsah + query.membrana;
  

    return jsonData.get(`/tlakoveSnimace/?page=${page}${query}`);
  }

  postForm(data) {

    return form.post(`/form`, data);
  }
}
export default new ProductsDataService();
