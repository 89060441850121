import styled from "styled-components";

export const Wrapper = styled.div`
 
  margin: 0px auto;
  padding: 0 20px;

  h1 {
    color: var(--medGrey);
    padding: 20px 0px 0px 0px;
    @media screen and (max-width: 768px) {
      font-size: var(--fontBig);
    }
  }
`;
export const SpanNotification = styled.span`
  color: var(--darkGrey);
  font-size: var(--fontMed);
  position: relative;
`;
export const AllertWrapper = styled.div`
  display: flex;
  margin: 1em 0 0 0;
 

  justify-content: space-between;
`;
export const Confirmation = styled.div`
  line-height: 2rem;

  font-size: var(--font-Med);
`;
export const GDPRWrapper = styled.div`
padding: 1em 0;
font-size: var(--font-small);



`