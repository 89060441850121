import React, { useContext } from "react";
import useTeplotniSnimaceFetch from "../../hooks/useTeplotniSnimaceFetch";

const TeplotniSnimaceContext = React.createContext();

export function useTeplotniSnimaceContext() {
  return useContext(TeplotniSnimaceContext);
}

export default function TeplotniSnimaceProvider({ children }) {
  const productsData = useTeplotniSnimaceFetch();

  return (
    <TeplotniSnimaceContext.Provider value={productsData}>
      {children}
    </TeplotniSnimaceContext.Provider>
  );
}
