import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";
import { isPersistedState } from "../http-common";

let filters = {
  velikost: 0,
  rozsah: "",
  pripojeni: "",
  typ: "",
  category: "",
  page : 0
}
function useProductsFetch() {
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [velikost, setVelikost] = useState(filters.velikost);
  const [rozsah, setRozsah] = useState(filters.rozsah);
  const [pripojeni, setPripojeni] = useState(filters.pripojeni);
  const [typ, setTyp] = useState(filters.typ);
  const [category, setCategory] = useState(filters.category);
  const [page, setPage] = useState(filters.page);



  async function getProducts(
    velikost = 0,
    rozsah = "",
    pripojeni = "",
    typ = "",
    category = "",
    page = 0
  ) {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await ProductsDataService.getProducts(
        velikost,
        rozsah,
        pripojeni,
        typ,
        category,
        page
      );
  

      let totalPages;

      if (
        response.data.totalNumProducts % response.data.entries_per_page ===
        0
      ) {
        totalPages =
          parseInt(
            response.data.totalNumProducts / response.data.entries_per_page,
            10
          ) - 1;
      } else {
        totalPages = parseInt(
          response.data.totalNumProducts / response.data.entries_per_page,
          10
        );
      }

      setProducts((prev) => ({
        ...response.data,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : [...response.data.products],
        totalPages,
      }));
    } catch (err) {
      console.error(`Unable to fetch data in useHomePageFetch ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
  
    
  if(filters.velikost === velikost && filters.rozsah === rozsah && filters.pripojeni === pripojeni && filters.typ === typ && filters.category === category && filters.page === page){
    const sessionState = isPersistedState("Manometry")
    if(sessionState){
      setProducts(sessionState)

      return
    }    }
    getProducts(velikost, rozsah, pripojeni, typ, category, page)
 
  }, [velikost, rozsah, pripojeni, typ, category, page]);

//session storage
  useEffect(()=> {

   
    
        sessionStorage.setItem("Manometry",JSON.stringify(products))
 
   
    
     filters = {velikost, rozsah, pripojeni, typ, category, page}
  
  
     
  }, [products, velikost, rozsah, pripojeni,typ, category, page ])



  return {
    products,
    isLoading,
    isError,
    setVelikost,
    setRozsah,
    setPripojeni,
    setTyp,
    setCategory,
    setPage,
    page,
    velikost,
    rozsah,
    pripojeni,
    typ,
    category
  };
}

export default useProductsFetch;
