import styled from "styled-components";


export const ToggleMobileFilters = styled.span`
display: none;
:hover{
  cursor: pointer;
}
font-weight: bold;

@media screen and (max-width: 644px), screen and (max-height: 735px) {
    display: inline;

  }
`
export const Heading = styled.h1`
color: var(--darkGrey);
font-size: var(--fontSuperBig);
`
export const Description = styled.p`
color: var(--medGrey);
`
export const Left = styled.aside`
  z-index: 10000;
  position: fixed;
  top: 9.5rem;
  

  @media screen and (max-width: 644px), screen and (max-height: 735px) {
    display: ${props=> props.isMobileFilters? "inline-block" : "none"};
  left: 50%;
  transform: translate(-50%, -5rem);

  }
  @media screen and (max-height: 788px) {
    top: 6rem;

  }


`;
export const Right = styled.div`
  margin: 0 20px 0 18em;
  @media screen and (max-width: 644px) {
    display: grid;
    justify-content: center;
    margin: 20px;
    
  }
  @media screen and (max-height: 735px) {
    margin: 0 6rem 0 6rem;
    
  }
  @media screen and (min-width: 2000px) {
    margin: 0 20px 0 20em;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;

  margin: 20px 0;
`;
export const Wrapper = styled.div`
  
`;
export const NotFound = styled.div`
  line-height: 2rem;
  padding: 20px 20px;
  font-size: 1.2rem;
`;
