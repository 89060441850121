import React from "react";
import HomepageImage from "./HomePageImage/HomepageImage";
import Inquiry from "./Inquiry";
import Products from "./Products";
import ContactPage from "./ContactPage";
import About from "./About";


function HomePage() {
  document.title = `Měřící a regulační technika | MANOTHERM CZ`;


  
    
  return (
    <>
     
      <HomepageImage />
      <Products />
      <Inquiry />
      <ContactPage />
      <About />

    </>
  );
}

export default HomePage;
