import React, {useState} from "react";

import { Paper } from "./Form/Form.styled";
import CookieBar from "./CookieBar/CookieBar";
function PrivacyPolicy() {
const [isCookieUpdateVisible, setIsCookieUpdateVisible] = useState(false)

  return (
    <>
    {isCookieUpdateVisible ? <CookieBar isCookieUpdateVisible = {isCookieUpdateVisible} /> : ""}
      <Paper>
        <div style={{ padding: "20px", lineHeight: "1.5" }}>
        <h2 style={{ textAlign: "center" }}>Ochrana osobních údajů a nastavení cookies</h2>
      
          <p style={{ color: "#000" }}>
            Údaje poskytnuté v kontaktním formuláři využíváme pro vypracování
            obchodní nabídky a v dalších fázích (doprava apod.) obchodního
            styku.
          </p>
          <p style={{ color: "#000" }}>
            Cookies jsou malé soubory uložené ve Vašem webovém prohlížeči.
            Využíváme je pro anonymizovanou analýzu provozu na našich webových
            stránkách a personalizaci reklamy. Nastavení cookies odpovídá
            udělenému souhlasu při Vašem prvotním navštívení našich stránek. Své
            rozhodnutí můžete kdykoliv změnit po kliknutí na <span onClick={()=> setIsCookieUpdateVisible(true)} style = {{fontWeight: "bold", cursor: "pointer"}}>tento odkaz</span>, který
            znovu zobrazí nastavení cookies.
          </p>
          Více v této <a href = "#cookies" style={{textDecoration: "none", color: "#023", fontWeight: "bold"}}>části</a>.
            <h2 style={{ textAlign: "center" }}>Zásady ochrany osobních údajů</h2>
          <ol>
            <li>
              Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského
              parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v
              souvislosti se zpracováním osobních údajů a o volném pohybu těchto
              údajů (dále jen: „GDPR”) je MANOTHERM CZ s.r.o. IČ: 28029445 se
              sídlem: V Kamení 406/8, Plzeň. (dále jen: „správce“).
            </li>
            <li>
              Kontaktní údaje správce jsou shodné s kontaktními údaji firmy.
            </li>
            <li>
              Osobními údaji se rozumí veškeré informace o identifikované nebo
              identifikovatelné fyzické osobě; identifikovatelnou fyzickou
              osobou je fyzická osoba, kterou lze přímo či nepřímo
              identifikovat, zejména odkazem na určitý identifikátor, například
              jméno, identifikační číslo, lokační údaje, síťový identifikátor
              nebo na jeden či více zvláštních prvků fyzické, fyziologické,
              genetické, psychické, ekonomické, kulturní nebo společenské
              identity této fyzické osoby.
            </li>
          </ol>
          <h4>Zdroje a kategorie zpracovávaných osobních údajů</h4>
          <ol>
            <li>
              Správce zpracovává osobní údaje, které jste mu poskytl/a nebo
              osobní údaje, které správce získal na základě plnění Vaší
              objednávky
            </li>
            <li>
              Správce zpracovává Vaše identifikační a kontaktní údaje a údaje
              nezbytné pro plnění smlouvy.
            </li>
          </ol>
          <h4>Zákonný důvod a účel zpracování osobních údajů</h4>
          <ol>
            <li>Zákonným důvodem zpracování osobních údajů je:</li>
            <ul>
              <li>
                plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b
                GDPR
              </li>
              <li>
                oprávněný zájem správce na poskytování přímého marketingu podle
                čl. 6 odst. 1 písm. f GDPR
              </li>
              <li>
                Váš souhlas se zpracováním pro účely poskytování přímého
                marketingu zejména pro zasílání obchodních sdělení a newsletterů
                podle čl. 6 odst. 1 písm. a GDPR ve spojení s § 7 odst. 2 zákona
                č. 480/2004 Sb., o některých službách informační společnosti v
                případě, že nedošlo k objednávce zboží nebo služby.{" "}
              </li>
            </ul>
            <li>Účelem zpracování osobních údajů je</li>
            <ul>
              <li>
                vyřízení Vaší objednávky a výkon práv a povinností vyplývajících
                ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou
                vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení
                objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů
                je nutným požadavkem pro uzavření a plnění smlouvy, bez
                poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze
                strany správce plnit
              </li>
              <li>
                zasílání obchodních sdělení a činění dalších marketingových
                aktivit.
              </li>
              <li>
                Váš souhlas se zpracováním pro účely poskytování přímého
                marketingu zejména pro zasílání obchodních sdělení a newsletterů
                podle čl. 6 odst. 1 písm. a GDPR ve spojení s § 7 odst. 2 zákona
                č. 480/2004 Sb., o některých službách informační společnosti v
                případě, že nedošlo k objednávce zboží nebo služby.{" "}
              </li>
            </ul>
            <li>
              Ze strany správce nedochází / dochází k automatickému
              individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým
              zpracováním jste poskytl/a svůj výslovný souhlas
            </li>
          </ol>
          <h4>Doba uchovávání údajů</h4>
          <ol>
            <li>Správce uchovává osobní údaje</li>
            <ul>
              <li>
                po dobu nezbytnou k výkonu práv a povinností vyplývajících ze
                smluvního vztahu mezi Vámi a správcem a uplatňování nároků z
                těchto smluvních vztahů (po dobu 15 let od ukončení smluvního
                vztahu)
              </li>
              <li>
                po dobu, než je odvolán souhlas se zpracováním osobních údajů
                pro účely marketingu, nejdéle …. let, jsou-li osobní údaje
                zpracovávány na základě souhlasu.{" "}
              </li>
              <li>
                Váš souhlas se zpracováním pro účely poskytování přímého
                marketingu zejména pro zasílání obchodních sdělení a newsletterů
                podle čl. 6 odst. 1 písm. a GDPR ve spojení s § 7 odst. 2 zákona
                č. 480/2004 Sb., o některých službách informační společnosti v
                případě, že nedošlo k objednávce zboží nebo služby.{" "}
              </li>
            </ul>
            <li>
              Po uplynutí doby uchovávání osobních údajů správce osobní údaje
              vymaže
            </li>
          </ol>
          <h4>Příjemci osobních údajů (subdodavatelé správce)</h4>
          <ol>
            <li>Příjemci osobních údajů jsou osoby</li>
            <ul>
              <li>
                podílející se na dodání zboží / služeb / realizaci plateb na
                základě smlouvy
              </li>
              <li>podílející se na zajištění provozu služeb</li>
              <li>zajišťující marketingové služby.</li>
            </ul>
          </ol>
          <h4>Vaše práva</h4>
          <ol>
            <li>Za podmínek stanovených v GDPR máte</li>
            <ul>
              <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR</li>
              <li>
                právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
                zpracování dle čl. 18 GDPR.{" "}
              </li>
              <li>právo na výmaz osobních údajů dle čl. 17 GDPR. </li>
              <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a </li>
              <li>právo na přenositelnost údajů dle čl. 20 GDPR. </li>
              <li>
                právo odvolat souhlas se zpracováním písemně nebo elektronicky
                na adresu nebo email správce uvedený v čl. III těchto podmínek.{" "}
              </li>
            </ul>
            <li>
              Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů
              v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu
              osobních údajů
            </li>
          </ol>
          <h4>Podmínky zabezpečení osobních údajů</h4>
          <ol>
            <li>
              Správce prohlašuje, že přijal veškerá vhodná technická a
              organizační opatření k zabezpečení osobních údajů.
            </li>

            <li>
              Správce přijal technická opatření k zabezpečení datových úložišť a
              úložišť osobních údajů v listinné podobě
            </li>
            <li>
              právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
              zpracování dle čl. 18 GDPR.{" "}
            </li>
            <li>
              Správce prohlašuje, že k osobním údajům mají přístup pouze jím
              pověřené osoby
            </li>
          </ol>
          <h4>Závěrečná ustanovení</h4>
          <ol>
            <li>
              Odesláním objednávky z internetového objednávkového formuláře
              potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
              údajů a že je v celém rozsahu přijímáte.
            </li>

            <li>
              S těmito podmínkami souhlasíte zaškrtnutím souhlasu
              prostřednictvím internetového formuláře. Zaškrtnutím souhlasu
              potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
              údajů a že je v celém rozsahu přijímáte.
            </li>
            <li>
              Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek
              ochrany osobních údajů zveřejní na svých internetových stránkách,
              případně Vám zašle novou verzi těchto podmínek na e-mailovou
              adresu, kterou jste správci poskytl/a.
            </li>
          </ol>
        
            <h2 style={{ textAlign: "center" }} id = "cookies">Použití cookies</h2>
            <h4>Úvod</h4>
            <p style={{ color: "#000" }}>
              Naše webové stránky https://www.manometry-tlakomery.cz (dále jen
              „web“) používají cookies a další podobné technologie (dále jen
              „cookies“). Používáme také cookies od třetích stran. Níže najdete
              podrobné informace o použití cookies na našem webu, jak dát
              souhlas s jejich používáním, jak souhlas odvolat a jaká jsou vaše
              práva jako návštěvníka webu.
            </p>
            <h4>Co jsou cookies a k čemu slouží?</h4>
            <p style={{ color: "#000" }}>
              Cookies jsou krátké textové soubory, které vytváří webový server a
              ukládá je v počítači prostřednictvím prohlížeče. Při návštěvě webu
              počítač požádá vaše zařízení o povolení k uložení tohoto souboru.
              Informace uložené pomocí cookies pak mohou být během návštěvy webu
              vráceny našim serverům nebo serverům třetí strany.
            </p>
            <h4>Jaké typy cookies se používají?</h4>
            <ul>
              <li>Nezbytné soubory cookies</li>
              <p style={{ color: "#000" }}>
                Jejich použití zajišťuje správné načtení a funkci webu jako
                takového. Tyto cookies můžeme umístit bez vašeho souhlasu.
              </p>
              <li>Analytické cookies a cookies výkonu</li>
              <p style={{ color: "#000" }}>
                Použití těchto cookies umožňuje získávat informace o tom, jak se
                uživatelé na webu chovají a jak web pracuje. Tyto informace pak
                slouží k další optimalizaci webu pro uživatele. K použití těchto
                cookies žádáme váš souhlas
              </p>
              <li>Marketingové cookies</li>
              <p style={{ color: "#000" }}>
                Tyto cookies se používají k cílení reklamy na uživatele. Pomocí
                nich lze zobrazovat reklamu přizpůsobenou uživateli a jeho
                preferencím, a to jak na tomto webu, tak i na partnerských
                webech. Použití těchto cookies také umožňuje shromažďovat údaje
                o reklamě (kolikrát byla shlédnuta/kolikrát se uživatelé
                proklikli) a optimalizovat podle nich reklamní kampaně. K
                použití těchto cookies žádáme váš souhlas.
              </p>
            </ul>
         
          
            <h4>Udělení souhlasu se zpracováním cookies</h4>
            <p style={{ color: "#000" }}> Při první návštěvě webu je uživateli nabídnuto vyskakovací okno Používáme cookies. Odkliknutím některého z tlačítek (Přijmout vše/Pouze analytické/Odmítnout vše) vyjadřujete svůj souhlas s použitím souboru cookies v nastaveném rozsahu.</p>
            <h4>Odvolání souhlasu se zpracováním cookies a jejich odstranění</h4>
            <p style={{ color: "#000" }}>Pokud chcete jako uživatel svůj souhlas odvolat nebo změnit nastavení cookies, může tak učinit odkazem v úvodu stránky. Tento odkaz opětovně zobrazuje okno s nastavením cookies, kde je možné své předvolby změnit či zrušit. </p>
            <p style={{ color: "#000" }}>Cookies může uživatel také zakázat nebo odstranit přímo ve svém internetovém prohlížeči. Bližší informace jsou k dispozici v nápovědě daného prohlížeče. </p>
            <h4>Další informace o využití cookies</h4>
            <p style={{ color: "#000" }}>Další informace k dočtení na tomto <a href="https://policies.google.com/technologies/cookies?hl=cs"target="_blank" rel="noreferrer" style={{textDecoration: "none", color: "#023", fontWeight: "bold"}}>odkazu</a>. </p>
          Tyto podmínky nabývají účinnosti dnem 22.03.2022.
        </div>
      </Paper>
    </>
  );
}
export default PrivacyPolicy;
