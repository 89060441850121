import React from "react"
import PropTypes from "prop-types"
import { Wrapper, Content } from "./Breadcrumb.styled"

import { StyledLink } from "../Header/Header.styles"

function Breadcrumb({title, linkTo}){
    let category = ""
    switch (linkTo) {
        case "manometry":
            category = "Manometry"
            break;
        case "teplomery":
            category = "Teploměry"
                break;
                case "teplotniSnimace":
                    category = "Teplotní snímače"
                    break;
                    case "tlakoveSnimace":
                        category = "Tlakové snímače"
                        break;
                        case "prislusenstvi":
                            category = "Příslušenství"
                            break;
        default:
            break;
    }


    return( 
          <Wrapper>
        <Content>
        <StyledLink to = "/produkty">
        <span>Produkty</span>
        </StyledLink>
        <span>|</span>
        <StyledLink to = {`/produkty/${linkTo}`}>
        <span>{category}</span>
        </StyledLink>
        <span>|</span>
        <span>{title}</span>
        </Content>
    </Wrapper>)
}
Breadcrumb.propTypes = {
    title: PropTypes.string
}
export default Breadcrumb