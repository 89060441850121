import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root {
    --maxWidth: 90%;
    --white: #fff;
    --lightGrey: #eee;
    --medGrey: #353535;
    --darkGrey: #1c1c1c;
    --fontSuperBig: 2rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
   

    --baseBlueColor: #023;



}

#root{
    display:flex;
    flex-direction: column;
       min-height: 100vh;
}
#main {
   flex: 1;
  
}

* {
    box-sizing: border-box;
    font-family: "Abel", sans-serif;
}
body {
    margin: 0;
    padding: 0;
    background: #eee;
    overflow-y: scroll;

  
    
   

    
    h1 {
        font-size: var(--fontSuperBig);
        font-weight:600;
        color: var(--white);
    }
    h3 {
        font-size: var(--fontSmall);
        font-weight: 600;
    }
    p {
        font-size: var(--fontSmall);
        color: var(--white)
    }


}
@media (min-width: 320px) {
  :root {
    --fontSuperBig: 1.5rem;
    --fontBig: 1.2rem;
    --fontMed: 1rem;
    --fontSmall: 0.8rem;
  }
@media (min-width: 414px) {
  :root {
    --fontSuperBig: 1.8rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
  }

  @media (min-width: 1366px) {
  :root {
    --fontSuperBig: 2.2rem;
    --fontBig: 1.8rem;
    --fontMed: 1.4rem;
    --fontSmall: 1rem;
  }

@media (min-width: 2000px) {
  :root {
    --fontSuperBig: 3rem;
    --fontBig: 2.5rem;
    --fontMed: 2.2rem;
    --fontSmall: 1.5rem;
  }


`;
