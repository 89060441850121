import React from "react";
import {Wrapper} from "./Footer.styled"
function Footer(){

    const year = new Date().getFullYear()

    return (<>
        <Wrapper>

        <span>© {year} MANOTHERM CZ s.r.o.</span> <br />
       


        </Wrapper>
    
    
    
    
    </>)

}

export default Footer