import React from "react";
import { Button as ButtonStyled } from "./Button.styled";
function Button({
  text = "",
  onClick,
  type = "button",
  value = "Submit",
  disabled = false,
}) {
  return (
    <ButtonStyled
      onClick={onClick}
      type={type}
      value={value}
      disabled={disabled}
    >
      {text}{" "}
    </ButtonStyled>
  );
}

export default Button;
