import styled from "styled-components";

export const Wrapper = styled.div`
color: var(--white);
background: var(--darkGrey);
border-radius: 20px;
padding: 5px;
text-align:center;
font-size: var(--fontSmall);
}

h3{
    margin: 10px 0 0 0;
   
}
p {
    margin: 5px 0;
    font-size: var(--fontSmall);
}
}

`;
export const Image = styled.img`

  display: block;
  width: 100%;
  height: 200px;

  transition: all 0.3s;
  object-fit: cover;
  border-radius: 15px;
  animation: animateThumb 0.5s;
  :hover {
    opacity: 0.7;
  }
  @keyframes animateThumb {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
