import React, { useState, useEffect, useRef } from "react";

import usePageScrolled from "../../hooks/usePageScrolled";
import logo  from "../../Images/LogoTrace.svg"
import {
  Wrapper,
  Content,
  LogoTitle,
  Poptavka,

  StyledLink,
  Menu,
} from "./Header.styles";

function Header() {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const sidebarRef = useRef(null);
  function toggleMobileMenu() {
    return setIsMobileMenuVisible((prevState) => {
      return !prevState;
    });
  }

  usePageScrolled("header", "0px", "-100px");

  // useEffect(() => {
  //   if (!isMobileMenuVisible) {
  //   }
  // }, [isMobileMenuVisible, usePageScrolled, setisMobileMenuVisible]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isMobileMenuVisible
      ) {
        setIsMobileMenuVisible(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuVisible]);


  return (
    <Wrapper id="header">
     
        <LogoTitle>
           <StyledLink to="/">
          <img src= {logo} className="logo" alt = "Manotherm CZ logo"></img>
          </StyledLink>
        </LogoTitle>
      
      <Menu
        aria-controls="Content"
        aria-expanded="false"
        onClick={toggleMobileMenu}
        visible={isMobileMenuVisible}
      >
        <span>Menu</span>
      </Menu>

      <Content visible={isMobileMenuVisible} ref = {sidebarRef}>
        <span>
          <StyledLink to="/produkty">Produkty</StyledLink>
        </span>

        <span>
          <StyledLink to="/kontakt">Kontakt</StyledLink>
        </span>

        <span>
          <StyledLink to="/o-nas">O nás</StyledLink>
        </span>
  

    
          <Poptavka>
        <StyledLink to="/poptavka">Poptávka</StyledLink>
      </Poptavka>
      </Content>

    
    </Wrapper>
  );
}
export default Header;
