import styled from "styled-components";

export const Wrapper = styled.div`
  /* padding: 5px 10px; */

  width: 100%;
  p {
    padding: 5px 0;
    font-style: italic;

    font-weight: normal;
  }
  span {
    font-weight: bold;
  }
`;
