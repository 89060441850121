import React from "react";
import { useParams } from "react-router-dom";

import ProductProvider from "./Context/ProductContext";
import ProductDetail from "./Productdetail/Productdetail";

function Product() {
const {id} = useParams()
document.title = `${id} | MANOTHERM CZ`


  return (
    <>
      <ProductProvider>
        <ProductDetail />
      </ProductProvider>
    </>
  );
}
export default Product;
