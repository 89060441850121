import {
  Input,
  Label,
  Textarea,
  FileInput,
  FileLabel,
  FileWrapper
} from "./InputField.styled";
import { useState, useEffect, useRef } from "react";
import { useFormContext } from "../Context/FormContext";


let data = {};

function InputField({
  placeholderText = "",
  id = "",
  type = "",
  pattern = "",
  styleTyp = "text",
  isSubmitButtonClicked = false,
  setIsSubmitButtonClicked,
  uploadText = "Vybrat soubory",
}) {
  const [valueState, setValueState] = useState(data);
  const [textAreaHeight, setTextAreaHeight] = useState("");
  const textArea = useRef(null);

  const {
    setData,
    isRequiredSet,
    setIsRequired,
    setIsShowModal,
    setAlertInfo,
    setIsShowModalInvalidInput,
  } = useFormContext();

  function maxSelectFiles(e) {
    let files = e.target.files;
    if (files.length > 3) {
     
      e.target.value = null;
     
      setAlertInfo({
        alertText: "Maximálně lze odeslat 3 soubory",
        isAlertDisplayed: true,
      });
      setTimeout(() => {
        setAlertInfo({ isAlertDisplayed: false });
      }, 5000);
      return true;
    }
    return false;
  }
  function checkFileSize(e) {
    let files = e.target.files;
    let size = 1024 * 1024 * 5;

    Array.from(files).forEach((file) => {
      if (file.size > size) {
        e.target.value = null;
    
        setAlertInfo({
          alertText: "Maximální velikost souboru je 5 MB.",
          isAlertDisplayed: true,
        });
        setTimeout(() => {
          setAlertInfo({ isAlertDisplayed: false });
        }, 5000);
        return true;
      }
    });
    return false;
  }
  function checkType(e) {
    let files = e.target.files;
    let types = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    let response;
    Array.from(files).forEach((file) => {
    
      if (types.every((type) => file.type !== type)) {
        e.target.value = null;
    
        setAlertInfo({
          alertText: "Podporované soubory: .jpeg, .png, .gif, .pdf, .doc, .xsl",
          isAlertDisplayed: true,
        });
        setTimeout(() => {
          setAlertInfo({ isAlertDisplayed: false });
        }, 5000);
        return (response = true);
      }
      if (response) return true;
    });
    return false;
  }

  function handleChange(e) {
    if (e.target.files) {
      if (maxSelectFiles(e)) return;
      if (checkFileSize(e)) return;
      if (checkType(e)) return;
      const name = e.target.id;
      const text = e.target.value;
      const value = e.target.files;
      data[id] = value;
      setValueState((valueState) => ({ ...valueState, [name]: text }));
    
      return;
    }

    const name = e.target.id;
    const text = e.target.value;
    setValueState((valueState) => ({ ...valueState, [name]: text }));
    data[id] = text;
  }

  function autoResize() {
    if (!textArea.current) return;
    const currentHeight = textArea.current.scrollHeight;
    setTextAreaHeight(`${currentHeight}px`);
    return;
  }


  function validateInput() {
    if (
      !(
        /^[a-ž A-Ž]+$/.test(data.jmeno) &&
        (/^[0-9]+$/.test(data.ico) || data.ico === "" || !data.ico) &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email) &&
        (/^[+]?[()/0-9. -]{9,}$/.test(data.tel_cislo) ||
          data.tel_cislo === "" ||
          !data.tel_cislo)
      )
    ) {
      setIsShowModalInvalidInput(true);
    } else {
      setIsShowModalInvalidInput(false);

    }

    if (data.jmeno && data.email && data.text) {
      setIsRequired(true);
      setIsShowModal(false);
    } else {
      setIsRequired(false);
      setIsShowModal(true);
    }
  }

  useEffect(() => {
    autoResize();
  }, [autoResize]);

  useEffect(() => {
    if (isSubmitButtonClicked) {
      validateInput();
      setData(data);
      setIsSubmitButtonClicked(false);

      return;
    } else {
      return;
    }
  }, [data, isSubmitButtonClicked, validateInput]);

  return (
    <>
      {styleTyp === "textarea" && (
        <>
          <Label htmlFor={id}>
            <Textarea
              placeholder=""
              id={id}
              type={type}
              onChange={handleChange}
              value={valueState[id] || ""}
              input={valueState[id] ? ":valid + span" : ""}
              pattern={pattern ? pattern : null}
              ref={textArea}
              height={textAreaHeight}
              isRequiredSet={isRequiredSet}
            ></Textarea>
            <span>{placeholderText}</span>
          </Label>
        </>
      )}
      {styleTyp === "text" && (
        <>
          <Label htmlFor={id}>
            <Input
              placeholder=""
              id={id}
              type={type}
              onChange={handleChange}
              value={valueState[id] || ""}
              input={valueState[id] ? ":valid + span" : ""}
              pattern={pattern ? pattern : null}
              isRequiredSet={isRequiredSet}
            ></Input>
            <span>{placeholderText}</span>
          </Label>
        </>
      )}
      {styleTyp === "file" && (
        <>
    <FileWrapper>
          <FileLabel htmlFor={id}>
            {uploadText}
            <FileInput
              id={id}
              type={type}
              onChange={handleChange}
              multiple
              isRequiredSet={isRequiredSet}
            ></FileInput>

          </FileLabel>
        
          {data[id] ? Array.from(data[id]).map((file)=>{
            return <span key = {file.name}>{file.name}</span>
          }): ""}
         </FileWrapper>
       
        </>
      )} 
    </>
  );
}

export default InputField;
