import React from "react";
import TeplomeryProvider from "./Context/TeplomeryContext";
import Teplomery from "./Teplomery/Teplomery";
function TeplomeryPage() {
  document.title = `Teploměry | MANOTHERM CZ`
  return (
    <>
      <TeplomeryProvider>
        <Teplomery />
      </TeplomeryProvider>
    </>
  );
}
export default TeplomeryPage;
