import { useTeplomeryContext } from "../Context/TeplomeryContext";

function useTeplomerySearchBar() {
  const { setPrumer, setTyp, setPage,setPripojeni, setCategory, prumer, typ, category, pripojeni } = useTeplomeryContext();
  function reset() {
    setPrumer(0);
    setTyp("");
    setPripojeni("")
    setCategory("")
    setPage(0);
    return;
  }
  const searchBarData = [
    {
      handler: setTyp,
      value: [
        "mechanical",
        "digital"
      ],
      text: [
        "Mechanické",
        "Digitální"
      ],
      name: "typ",
      title: "Provedení",
      state: typ
    },

    {
      handler: setPrumer,
      value: [63, 80, 100, 160],
      text: ["63 mm", "80 mm", "100 mm", "160 mm"],
      name: "velikost",
      title: "Průměr pouzdra",
      state: prumer
    },   {
      handler: setPripojeni,
      value: ["Spodní", "Zadní centrické", "Kapilára"],
      text: ["Spodní", "Zadní centrické", "Kapilární"],
      name: "pripojeni",
      title: "Připojení",
      state: pripojeni
    },{
      handler: setCategory,
      value: [
        "standard",
        "prumysl",
        "kontakt",
       "termomanometr"

      ],
      text: [
        "Standardní",
        "Průmyslové",
        "Kontaktní",
        "Termomanometry",
    
      ],
      name: "category",
      title: "Kategorie",
      state: category
    },

  ];
  return { searchBarData, reset };
}
export default useTeplomerySearchBar;
