import React from "react";
import ManometryProvider from "./Context/ManometryContext";
import Manometry from "./Manometry/Manometry";


function ManometryPage() {

 document.title = "Manometry | MANOTHERM CZ"
  
  return (
    <>
      <ManometryProvider>
        <Manometry />
      </ManometryProvider>
    </>
  );
}
export default ManometryPage;
