import {
  GridWrapper,
} from "./ProductInfo.styled";
import ProductDetailGridItem from "../ProductDetailGridItem/ProductDetailGridItem";
function ProductInfo({product, rozsah1Title, rozsah2Title}){
  
    const flattenObject = (obj) => {
        const flattened = {};
    
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
    
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(flattened, flattenObject(value));
          } else {
            flattened[key] = value;
          }
        });
    
        return flattened;
      };
    
      const parametry = flattenObject(product.product.parametry);
    
      const rozsah1 = {
        ...product.product.rozsah.table1,
      };
    
      const rozsah2 = {
        ...product.product.rozsah.table2,
      };
    return ( <>
        <GridWrapper>
          <ProductDetailGridItem text={parametry} />
        </GridWrapper>

        <h4>Rozsah</h4>
        <GridWrapper>
          {Object.keys(rozsah1).length === 0 &&
          rozsah1.constructor === Object ? (
            ""
          ) : (
            <div className="center">
              <span>{rozsah1Title}</span>
              <ProductDetailGridItem
                displayKey={false}
                text={rozsah1}
              />
            </div>
          )}
          {Object.keys(rozsah2).length === 0 &&
          rozsah2.constructor === Object ? (
            ""
          ) : (
            <div className="center">
              <span>{rozsah2Title}</span>
              <ProductDetailGridItem
                displayKey={false}
                text={rozsah2}
              />
            </div>
          )}
        </GridWrapper>
      </>
    )
}
export default ProductInfo