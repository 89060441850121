import React from "react";

import { useProductContext } from "../Context/ProductContext";
import {
  Wrapper,
  TitleBox,
  Content,
  Image,
  ImageWrapper,
  GridWrapperTable,
  Block,
  ProductDetailHeader,
} from "./ProductDetail.styled";
import Error from "../Error/Error";
import Spinner from "../Spinner/Spinner";
import ProductDetailGridItem from "../ProductDetailGridItem/ProductDetailGridItem";
import { Paper } from "../Form/Form.styled";
import BasicTable from "../BasicTable/BasicTable";
import useRequireImage from "../../hooks/useRequireImage";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Grid from "../Grid/Grid";
import ProductInfo from "../ProductInfo/ProductInfo";

function ProductDetail() {
  const { product, isError, isLoading, productCategory, isNotFound } =
    useProductContext();

  const tryRequire = useRequireImage();

  if (isNotFound && !isLoading)
    return (
      <>
        <Error
          text={"Hledaný produkt s tímto ID neexistuje."}
          backTo="produkty"
        />
      </>
    );

  if (product.length < 1)
    return (
      <>
        {isLoading ? <Spinner /> : ""}
        {isError && !isLoading ? (
          <Error
            text={"Při načítání dat nastala chyba, zkuste to prosím později"}
            backTo="produkty"
          />
        ) : (
          ""
        )}
      </>
    );

  const mods = product.product.mods
    ? {
        ...product.product.mods,
      }
    : "";

  const logo = tryRequire(product.product.main_image_url);

  function chooseLoad(productCategory, product) {
   
    switch (productCategory) {
      case "manometry":
        return (
          <ProductInfo
            product={product}
            rozsah1Title={"Bar"}
            rozsah2Title={"Pa"}
          />
        );
      case "teplomery":
        return (
          <ProductInfo
            product={product}
            rozsah1Title={"Teplota"}
            rozsah2Title={"Délka konektoru"}
          />
        );
      case "tlakoveSnimace":
        return <ProductInfo product={product} rozsah1Title={"Bar"} />;
      case "teplotniSnimace":
        return (
          <ProductInfo
            product={product}
            rozsah1Title={"Teplota"}
            rozsah2Title={"Délka konektoru"}
          />
        );
      case "prislusenstvi":
        return (
          <>
            <BasicTable variants={product.product.varianty} />
            <GridWrapperTable>
              <Grid
                min="15em"
                content={product.product.varianty.map((variant) => {
                  return (
                    <Block key={product.product.varianty.indexOf(variant)}>
                      {Object.keys(variant).map((key, i) => {
                        return (
                          <React.Fragment key={i}>
                            <p>
                              {key}: <span>{variant[key]}</span>
                            </p>
                          </React.Fragment>
                        );
                      })}
                    </Block>
                  );
                })}
              />
            </GridWrapperTable>
          </>
        );
      default:
    
        return null;
    }
  }

  return (
    <>
      <Breadcrumb
        title={product.product.supplier_id}
        linkTo={productCategory}
      />
      <Paper>
        <Wrapper>
          <ProductDetailHeader>
            <TitleBox>
              <h3>{product.product.title}</h3>
              <p className="textTitle">{product.product.supplier_id}</p>
              <p>{product.product.supplier_old_id}</p>
            </TitleBox>

            <ImageWrapper>
              <Image
                src={logo}
                alt={`Product image ${product.product.title}`}
              />
            </ImageWrapper>
          </ProductDetailHeader>

          <Content>
            <h4>Popis</h4>
            <p className="sectionDescription">{product.product.description}<span>{product.product.tags}</span></p>

            <h4>Technické údaje</h4>
            {chooseLoad(productCategory, product)}

            {mods ? (
              <>
                <h4>Doplňky</h4>
                <p className="sectionDescription">
                  Ke standardnímu provedení je možné dokoupit tyto položky:{" "}
                </p>
                <ProductDetailGridItem text={mods} displayKey={false} />
              </>
            ) : (
              ""
            )}
          </Content>
        </Wrapper>
      </Paper>
    </>
  );
}
export default ProductDetail;
