import styled from "styled-components";

export const Wrapper = styled.div`

--background: #ffffff;
    --text: #414856;
    --radio: #7c96b2;
    --radio-checked: #4f29f0;
    --radio-size: 20px;
    --spaces: 5px;


  font-size: var(--fontSmall);
  width: 250px;

  margin: 20px;
  padding: 15px 0 0 0;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
overflow: scroll;

@media screen and (max-width: 644px) {
  margin: 0px;

  }
  

max-width: 519px;

::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  span {
   
   
    font-size: var(--fontSmall);

  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    height: var(--radio-size);
    width: var(--radio-size);
    outline: none;
    margin: 0;
    cursor: pointer;
    border: 2px solid var(--radio);
    background: transparent;
    border-radius: 50%;
    display: grid;
    justify-self: end;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    transition: border 0.5s ease;
    margin: var(--spaces) 0;
    
    &::before,
    &::after {
      content: "";
      display: flex;
      justify-self: center;
      border-radius: 50%;
    }
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--background);
      z-index: 1;
      opacity: var(--opacity, 1);
    }
    &::after {
      position: relative;
      width: calc(100% / 2);
      height: calc(100% / 2);
      background: var(--radio-checked);
      top: var(--y, 100%);
      transition: top 0.5s cubic-bezier(0.48, 1.97, 0.5, 0.63);
    }
    &:checked {
      --radio: var(--radio-checked);
      &::after {
        --y: 0%;
        animation: stretch-animate 0.3s ease-out 0.17s;
      }
      &::before {
        --opacity: 0;
      }
      ~ input[type="radio"] {
        &::after {
          --y: -100%;
        }
      }
    }
    &:not(:checked) {
      &::before {
        --opacity: 1;
        transition: opacity 0s linear 0.5s;
      }
    }

    @keyframes stretch-animate {
      0% {
        transform: scale(1, 1);
      }
      28% {
        transform: scale(1.15, 0.85);
      }
      50% {
        transform: scale(0.9, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  label {
    cursor: pointer;
    margin: var(--spaces) 0;
  }

 `


export const Title = styled.span`
 display:flex;
 justify-content: center;
 font-weight: bold;
 padding:  10px 0px;
 border-bottom: 2px solid #eee;
 @media screen and (max-width: 644px) {
  padding:  2px 0px 4px 0;

  cursor: pointer;
  :hover{
    opacity: 0.5;
  }
  }

`
export const Accordion = styled.button`
 background-color: ${(props) => props.active? `#eee` :`white`};
  color: #444;
  cursor: pointer;
  padding: 8px 0 ;
  max-height: 50px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  outline: none;
  transition: 0.4s;


  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  color: var(--text);

  position: relative;
 
  border: none;

:hover{
    background-color: #ccc;
  }

:after{
  
  
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  }


`
export const Panel = styled.div`
  padding: 0 18px;
  background-color: white;
  max-height: ${(props) => props.active? "1000px" :"0"};
  overflow: hidden;
  opacity: ${(props) => props.active ? '1' : '0'};
  transition: max-height 0.8s ease-out, opacity 0.8s ease-out;

 

  color: var(--text);
  position: relative;
  display: grid;
  grid-template-columns: auto var(--radio-size);
  
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
`;
export const CategoryTitle = styled.span`
padding: 10px 0px 10px 10px;
`
export const FilterTitle = styled.span`
padding: 10px 10px 10px 0px;
`