import styled from "styled-components";

export const Wrapper = styled.div`
  color: var(--white);
  background: #023;
  border-radius: 20px;
  padding: 5px;
  text-align: center;

  h3 {
    margin: 10px 0 0 0;
    font-size: var(--fontSmall);
  }
  p {
    margin: 5px 0;
    font-size: var(--fontSmall);
  }
  :hover {
    opacity: 0.7;
  }
`;
export const Content = styled.div`
  height: 200px;
  background: var(--white);
  border-radius: 15px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;

  transition: all 0.3s;
  object-fit: contain;
  border-radius: 15px;
  animation: animateThumb 0.5s;

  @keyframes animateThumb {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
