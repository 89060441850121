import styled from "styled-components";


export const TilesWrapper = styled.div`
display:flex;
flex-wrap: wrap;
`
export const Tile = styled.div`
line-height: 1.5;
width:  50%;
overflow: hidden;
border-radius: ${props => !props.border_radius ? "0px": props.border_radius}; 
font-size: var(--fontSmall);


@media screen and (max-width: 1300px) {
 width: 100%;
 border-radius: 15px 15px 0 0;  
  }
p {
    color: #000;
    font-size: var(--fontSmall);
    line-height: 1.2;
    padding: 0px 20px;
    margin: 0;

    :last-child{
      padding: 0 20px 10px 20px;
    }
}
h4{
    padding: 0 20px;
    margin: 1em 0;
}
a {
       
      :link {
        text-decoration: none;
        color: hsla(200, 100%, 10%, 1);
      }
      :hover, :visited {
        color: hsla(200, 100%, 10%, 0.5);
      
}
 
}

`