function useRequireImage(){

    function tryRequire(typ){
        try {   
    
       
      
          return require(`../Images/${typ}`)
        }catch(err){
         
          return require(`../Images/No_Image_Available.jpg`)
        }
      }

      


      return tryRequire
}
export default useRequireImage