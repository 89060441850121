import styled from "styled-components";

export const Input = styled.input`
  font-size: var(--fontSmall);
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid;
  border-color: "#023";
  outline: none;

  :focus + span,
  ${(props) => props.input} {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
  }
  :invalid {
    color: red;
  }
  :invalid + span {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
  }
`;
export const FileInput = styled.input`
 	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`
export const FileLabel = styled.label`
display: inline-block;
  margin: 0 2em 0 0;
  
   background-color: #023;
  border-radius: 8px;
  border-style: none;
 
  color: #ffffff;
  

  font-size: var(--fontSmall);
  font-weight: 500;

  line-height: 1.5em;
  list-style: none;
  
  outline: none;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  :hover {
    cursor: pointer;
    background-color: hsla(200, 100%, 10%, 0.5);
  }
}
 
`
export const FileWrapper = styled.div`

display: flex;
flex-wrap: wrap;
justify-content: flex-start;
align-items: center;
gap: 1em;
`

export const Textarea = styled.textarea`
  font-size: var(--fontSmall);
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid;
  border-color: "#023";
  outline: none;
  resize: none;

  height: ${(props) => props.height};
  ::-webkit-scrollbar {
    display: none;
  }

  :focus + span,
  ${(props) => props.input} {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
  }

  :invalid {
    color: red;
  }
  :invalid + span {
    opacity: 1;
    transform: scale(0.75) translateY(-100%) translateX(-30px);
  }
`;

export const Label = styled.label`
  font-size: var(--fontSmall);
  margin: 20px 0;
  position: relative;
  display: inline-block;
  width: 100%;

  span {
    padding: 12px 20px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
  }
`;
