import React from "react";

function InputCheck({ handleClick, value, type = "", name, text, setPage, checked  }) {
  function click(e) {
    setPage(0);
 
    return handleClick(value);
  }

  return (
    <>
      <label htmlFor={value}>{text}</label>
      <input type={type} id={value} onChange={click} name={name} checked = {checked} />
    </>
  );
}

export default InputCheck;
