import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  background: hsla(200, 100%, 10%, 0.9);
  color: #415868;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
    padding: 20px 30px 0px 30px;
    color: #415868;
  }
  @media screen and (max-width: 644px) {
    width: 350px;
    height: 350px;
  }
`;

export const Modal = styled.div`
  position: relative;
  width: 280px;
  height: 310px;

  background: #fff;
  border-radius: 3px;
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  overflow: hidden;
  animation: show-modal 0.7s ease-in-out;

  &.hide {
    animation: hide-modal 0.6s ease-in-out both;
  }

  @keyframes show-modal {
    0% {
      transform: scale(0);
    }
    60% {
      transform: scale(1.1);
    }
    80% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes hide-modal {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

export const Image = styled.img`
  margin-top: 24px;
  width: "44px";
  height: "38px";
`;
export const Title = styled.span`
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 14px 0 5px 0;
`;
export const Button = styled.button`
  background-color: #023;

  border-style: none;

  color: #ffffff;
  cursor: pointer;

  font-size: 15px;
  font-weight: 500;

  line-height: 25px;
  list-style: none;
  width: 50%;
  outline: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;

  :hover {
    background-color: hsla(200, 100%, 10%, 0.5);
  }
`;
export const ButtonWrapper = styled.div`
position: absolute;
width: 100%;
  bottom: 0;
  left: 0;
`;
