import React, { useMemo } from "react";
import { useTable } from "react-table";
import { Wrapper } from "./BasicTable.styled";

function BasicTable({ variants, header }) {
  const keys = Object.keys(variants[0]);

  const COLUMNS = keys.map((key) => {
    return {
      Header: key,
      accessor: key,
    };
  });

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => variants, []);

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <Wrapper>
        <h4>{header}</h4>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headergroup) => {
              return (
                <tr {...headergroup.getHeaderGroupProps()}>
                  {headergroup.headers.map((column) => {
                    return (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
}

export default BasicTable;
