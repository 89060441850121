import React from "react";
import { Wrapper} from "./ProductDetailGridItem.styled";

function ProductDetailGridItem({ text, displayKey = true }) {
  return (
    <>
      {Object.entries(text).map(([key, value]) => {
        return (
          <Wrapper key={key}>
            <div>
              {" "}
              {value ? (
                <>
                  <span>{displayKey ? key : ""} </span>
                  <p>{value}</p>
                </>
              ) : (
                ""
              )}{" "}
            </div>
          </Wrapper>
        );
      })}
    </>
  );
}

export default ProductDetailGridItem;
