import React from "react";
import {Wrapper} from "./Alert.styled"

function Alert({alertText, isDisplayed}){


    return(<>

        <Wrapper isDisplayed = {isDisplayed}>
            <span>{alertText}</span>
        </Wrapper>
    
    
    
    
    
    </>)
}
export default Alert