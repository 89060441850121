import React from "react";
import Grid from "./Grid/Grid";
import manometry from "../Images/manometry.webp";
import teplomery from "../Images/teplomery.webp"
import prislusenstvi from "../Images/prislusenstvi.webp"
import Item from "./Item/Item";
import teplotniSnimace from "../Images/teplotni_snimace2.webp"
import tlakoveSnimace from "../Images/tlakove_snimace.webp"
import { useLocation } from "react-router-dom";
import { StyledLink } from "./Header/Header.styles";

function Products() {
  const path = useLocation()

  if(!(path.pathname === "/"))document.title = `Produkty | MANOTHERM CZ`



  return (
    <><div style={{"padding": "0 20px"}}> 
      <Grid
        header="Produkty"
        size="1fr"
        maxWidth = "1920px"
        margin = "0 auto"
        content={
         
          <>
            <StyledLink to="/produkty/manometry">
              <Item
                imageSrc={manometry}
                description="Mechanické a digitální"
                itemName="Manometry"
              />
            </StyledLink>
            <StyledLink to="/produkty/teplomery">
              <Item
                imageSrc={teplomery}
                description="Bimetalové, tlakové, digitální"
                itemName="Teploměry"
              />
            </StyledLink>
            {/* <StyledLink to="/produkty/tlakoveSnimace">
              <Item
                imageSrc={tlakoveSnimace}
                description="Piezorezistivní"
                itemName="Tlakové snímače"
              />
            </StyledLink>
            <StyledLink to="/produkty/teplotniSnimace">
              <Item
                imageSrc={teplotniSnimace}
                description="Odporové a termoelektrické"
                itemName="Teplotní snímače"
              />
            </StyledLink> */}
            
            <StyledLink to="/produkty/prislusenstvi">
              {" "}
              <Item
                imageSrc={prislusenstvi}
                description="Kohouty, ventily, smyčky, přechodky a další"
                itemName="Příslušenství"
              />
            </StyledLink>
          </>
        }
      /></div>
    </>
  );
}
export default Products;
