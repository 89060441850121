import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";
import { isPersistedState } from "../http-common";
let filters = {
  category: "",
  typ: "",
  page : 0
}
function usePrislusenstviFetch() {
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [typ, setTyp] = useState(filters.typ);
  const [category, setCategory] = useState(filters.category);
  const [page, setPage] = useState(filters.page);

  async function getProducts(typ = "", category = "", page = 0) {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await ProductsDataService.getPrislusenstvi(typ, category, page);
      let totalPages;

      if (
        response.data.totalNumProducts % response.data.entries_per_page ===
        0
      ) {
        totalPages =
          parseInt(
            response.data.totalNumProducts / response.data.entries_per_page,
            10
          ) - 1;
      } else {
        totalPages = parseInt(
          response.data.totalNumProducts / response.data.entries_per_page,
          10
        );
      }

      setProducts((prev) => ({
        ...response.data,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : [...response.data.products],
        totalPages,
      }));
    } catch (err) {
      console.error(`Unable to fetch data in usePrislusentviFetch ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if(filters.typ === typ && filters.category === category && filters.page === page){
      const sessionState = isPersistedState("Prislusenstvi")
      if(sessionState){
        setProducts(sessionState)
       
        return
      }    }
    getProducts(typ,category, page );

  }, [typ, category, page]);
  useEffect(()=> {
    sessionStorage.setItem("Prislusenstvi",JSON.stringify(products))
   
    filters = {typ, category, page}

    
 }, [products,category, typ, page])

  return { products, isLoading, isError, setTyp, setCategory, setPage, page, typ, category };
}

export default usePrislusenstviFetch;
