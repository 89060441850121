import React, { useContext } from "react";
import useProductsFetch from "../../hooks/useProductsFetch";

const ManometryContext = React.createContext();

export function useManometryContext() {
  return useContext(ManometryContext);
}

export default function ManometryProvider({ children }) {
  const productsData = useProductsFetch();

  return (
    <ManometryContext.Provider value={productsData}>
      {children}
    </ManometryContext.Provider>
  );
}
