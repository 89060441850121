import React from "react";

function NotFound() {
  document.title = `Hledaná stránka neexistuje | MANOTHERM CZ`
  
  return (
    <>
      <div>NotFound</div>
    </>
  );
}
export default NotFound;
