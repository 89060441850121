import React, { useContext } from "react";
import useProductFetchById from "../../hooks/useProductFetchById";
import { useParams } from "react-router";
const ProductContext = React.createContext();

export function useProductContext() {
  return useContext(ProductContext);
}

export default function ProductProvider({ children }) {
  const { id, productCategory } = useParams();

  const productData = useProductFetchById(id, productCategory);

  return (
    <ProductContext.Provider value={productData}>
      {children}
    </ProductContext.Provider>
  );
}
