import styled from "styled-components";

export const Wrapper = styled.footer`
    margin: 4rem 0 0 0;
  padding: 1rem;
    font-size: var(--fontSmall);
  text-align: center;

    a {
       font-style: italic;
       :link {
         text-decoration: none;
        color: #000;

       }
       :hover, :visited {
   
       
 }

`