import React from "react";
import { Wrapper, Image } from "./Item.styled";
function Item({ itemName, imageSrc, description }) {

 
  return (

    <Wrapper>
   
      <Image src={imageSrc} alt="Product image" />
      <h3>{itemName}</h3>
      <p>{description}</p>
    </Wrapper>
  );
}
export default Item;
