import React, { useContext } from "react";
import usePrislusenstviFetch from "../../hooks/usePrislusenstviFetch";

const PrislusenstviContext = React.createContext();

export function usePrislusenstviContext() {
  return useContext(PrislusenstviContext);
}

export default function PrislusenstviProvider({ children }) {
  const PrislusenstviData = usePrislusenstviFetch();

  return (
    <PrislusenstviContext.Provider value={PrislusenstviData}>
      {children}
    </PrislusenstviContext.Provider>
  );
}
