import { Wrapper, Content, Title, MapWrapper } from "./Contact.styled.js";
import Map from "../Map/Map.js";
import InfoBox from "../InfoBox/InfoBox.js";

function Contact() {
  return (
    <>
      <Wrapper>
        <h1>Kontaktujte nás</h1>
        <Title>MANOTHERM CZ s.r.o.</Title>
        <Content>
          <InfoBox
            title={"Fakturační údaje"}
            idTextUp={"IČO: "}
            textUp={"28029445"}
            textMid={"CZ28029445"}
            idTextMid={"DIČ: "}
            textLow={
              "Firma je zapsána v obchodním rejstříku vedeného Krajským soudem v Plzni oddíl C, vložka 21952."
            }
          />
          <InfoBox
            title={"Kontakt"}
            idTextUp={"Email: "}
            textUp={
              <a href={`mailto:obchod@havlik-manotherm.cz`} onClick={() => window.gtag("event", "email_clicked")}>obchod@havlik-manotherm.cz</a>
            }
            idTextMid={"Pevná linka: "}
            textMid={<a href="tel:+420377443066" onClick={() => window.gtag("event", "phone_clicked")}>+420 377 443 066</a>}
            idTextLow={"Mobil: "}
            textLow={<a href="tel:+420 603 732 574" onClick={() => window.gtag("event", "mobile_clicked")} >+420 603 732 574</a>}
          />
          <InfoBox
            title={"Adresa"}
            textUp={"Plzeň - Slovany"}
            textMid={"V Kamení 406/8"}
            textLow={"326 00"}
          />
        </Content>
            <MapWrapper>
                 <Map />
            </MapWrapper>
         



      </Wrapper>
    </>
  );
}

export default Contact;
