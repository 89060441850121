import {
  Wrapper,
  Modal,
  Image,
  Title,
  Button,
  ButtonWrapper,
} from "./Error.styled";
import { StyledLink } from "../Header/Header.styles";

function Error({ text, backTo }) {
  return (
    <>
      <Wrapper>
        <Modal>
          <Image src="https://100dayscss.com/codepen/alert.png" />
          <Title>Chyba</Title>
          <p>{text}</p>
          <ButtonWrapper>
            <StyledLink to={`/${backTo}`}>
              {" "}
              <Button>Zpět</Button>
            </StyledLink>
            <StyledLink to="/kontakt">
              <Button>Kontakt</Button>{" "}
            </StyledLink>
          </ButtonWrapper>
        </Modal>
      </Wrapper>
    </>
  );
}
export default Error;
