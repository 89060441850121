import axios from "axios";

//local
// export const jsonData = axios.create({
//   baseURL: "http://localhost:5000/api/v1/products/",
//   headers: {
//     "Content-type": "application/json"
//   },
// });



// production below
export const jsonData = axios.create({
  baseURL: "https://expressimage-ptih7dol5q-ew.a.run.app/api/v1/products/",
  headers: {
    "Content-type": "application/json",
  },
});

export const form = axios.create({
  baseURL: "https://expressimage-ptih7dol5q-ew.a.run.app/api/v1/products/",
  headers: {
    "Content-type": "multipart/form-data",
  },
});
export function isPersistedState(stateName){
  const sessionState = sessionStorage.getItem(stateName)
  return sessionState && JSON.parse(sessionState)
}

