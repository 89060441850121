import React, {useState, useEffect} from "react";

import {
  Wrapper,
  Content,
  Buttons,

} from "./CookieBar.styled";
import { StyledLink } from "../Header/Header.styles";

function CookieBar({isCookieUpdateVisible}) {

  const [isVisible, setIsVisible] = useState(false)
 

  const checkForCookieValue = (name) =>
    
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || false;

useEffect(()=>{
  if(!checkForCookieValue("consent_analytics") && !checkForCookieValue("consent_ads")){
       
        setIsVisible(true)
     }
     return
}, [])
useEffect(()=>{
 if(isCookieUpdateVisible) setIsVisible(isCookieUpdateVisible)


}, [isCookieUpdateVisible])
 


  function setAllCookiesConsent() {
    //cookies allowed for google ads and google analytics
    window.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 2)
    ).toUTCString();
    document.cookie = "consent_ads=yes; domain=manometry-tlakomery.cz; expires="+expires;
    document.cookie = "consent_analytics=yes; domain=manometry-tlakomery.cz; expires="+expires;
    setIsVisible(false)
  }
  function setDeclineAllCookiesConsent() {
    //cookies allowed for google ads and google analytics
    window.gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toUTCString();
    document.cookie = "consent_ads=no; domain=manometry-tlakomery.cz; expires="+expires;
    document.cookie = "consent_analytics=no; domain=manometry-tlakomery.cz; expires="+expires;
    setIsVisible(false)
  }

  function setOnlyAnalyticsCookiesConsent() {
    //cookies allowed for google ads and google analytics
    window.gtag("consent", "update", {
      analytics_storage: "granted",
    });
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 2)
    ).toUTCString();
    document.cookie = "consent_ads=no; domain=manometry-tlakomery.cz; expires="+expires;
    document.cookie = "consent_analytics=yes; domain=manometry-tlakomery.cz; expires="+expires;
    setIsVisible(false)
  }



  return isVisible ? (
  
      <Wrapper>
        <Content>
 
          <p>
            Cookies používáme pro zlepšení nabídky produktů a služeb, analýzu
            provozu na našich webových stránkách a personalizaci reklam.
            Používání cookies bude nastaveno pouze dle Vašeho souhlasu. Své rozhodnutí můžete kdykoliv změnit v sekci cookie managment. Více se
            můžete dozvědět na tomto {" "}
            <StyledLink to={"/ochrana-udaju"} color = {"#000"}>
              <span className="refference">odkazu</span>

            </StyledLink>
        
            .
          </p>
          <Buttons>
            <button className = "button declineAll"
        
              onClick={setDeclineAllCookiesConsent}
            >
              Odmítnout vše
            </button>

            <button
            className="button analyticsOnly"
            onClick={setOnlyAnalyticsCookiesConsent}>
              Pouze analytické
            </button>
            <button
            className="button acceptAll"
            onClick={setAllCookiesConsent}>
              Přijmout vše
            </button>
          </Buttons>
        </Content>
      </Wrapper>
   
  ): "";
}
export default CookieBar;
