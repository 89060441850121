import React from "react";

import FormProvider from "./Context/FormContext";
import InquiryForm from "./InquiryForm/InquiryForm";
import { useLocation } from "react-router-dom";
function Inquiry() {
  const path = useLocation()

  if(!(path.pathname === "/"))document.title = `Poptávka | MANOTHERM CZ`
  return (
    <>
      <FormProvider>
        <InquiryForm />
      </FormProvider>
    </>
  );
}
export default Inquiry;
