import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  font-size: var(--fontMed);
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  object-fit:  ${props => props.object_fit ? props.object_fit : "contain" };
  border-radius: ${props => props.border_radius ? props.border_radius : "15px" };
  animation: animateThumb 0.5s;

  @keyframes animateThumb {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export const ProductDetailHeader = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  background-color: var(--white);
  width: 50%;
  height: 25em;
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    display: block;
    max-height: none;
  }

  p {
    color: #000;
    margin: 0;
    font-size: var(--fontSmall);
  }

  h4 {
    margin: 1rem 0;
    color: var(--medGrey);
    font-size: var(--fontBig);
    /* @media screen and (max-width: 768px) {
      font-size: var(--fontMed);
    } */
  }
  .sectionDescription {
    margin: 1rem 0;
    span{
      font-style: italic;
    }
  
  }
`;
export const TitleBox = styled.div`
  width: 50%;
  height: 25em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #023;
  color: var(--white);

  h3 {
    font-size: var(--fontSuperBig);
    padding: 0 1rem 8rem 1rem;
    text-align: center;
    
    @media screen and (max-width: 644px) {
      font-size: var(--fontMed);
  }
  }

  p {
    font-size: var(--fontMed);
    margin: 1rem;
    color: #fff;
    @media screen and (max-width: 644px) {
      font-size: var(--fontSmall);
  }
  }
`;

export const GridWrapperTable = styled.div`
padding: 0 0 20px 0;
justify-content: center;
display: grid;


p {
    font-weight: bold;
    
}
span {
    font-weight: normal;
}

 @media screen and (min-width: 780px) {
      display: none;
    }
`
export const Block = styled.div`
    background: var(--white);
    border-style: solid;
    border-color: hsla(200,100%,10%,0.5);
    border-width: 2px;
    padding: 1em;
    

`
;
