import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";

function useFormPost() {
  const [data, setData] = useState();
  const [isSubmited, setIsSubmited] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRequiredSet, setIsRequired] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalInvalidInput, setIsShowModalInvalidInput] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [alertInfo, setAlertInfo] = useState({
    alertText: "",
    isAlertDisplayed: false
})

  async function postForm() {
   function getFormData(object) {
  
      const formData = new FormData();
      Object.keys(object).forEach((key) =>{
        if(key === "attachement"){
       
          Array.from(object[key]).forEach(file => {
            formData.append('attachement', file);
        });
          return
        }
        formData.append(key, object[key])
      } );
    
      return formData;
  }
 
    if (!data) return;
          if (!isRequiredSet) return;
      if(isShowModalInvalidInput) return
   
    try {
    
      setIsSubmited(false);
      setIsError(false);
    
 
   
      setIsLoading(true);
      window.gtag("event", "poptavka_sent")
      const toBeSent = getFormData(data)
      const response = await ProductsDataService.postForm(toBeSent);
  
    } catch (err) {
      if (err.response.status === 400) {
        setIsLoading(false);
        let attribut = err.response.data.attribut;
    
        setIsRequired(false);
        setIsShowModalInvalidInput({ attribut });
        return;
      }
      console.error(`Cannot post form ${err}`);
      setIsSubmited(false);
      setIsError(true);
    }
    setIsLoading(false);
    setIsSubmited(true);

   
  }
//postform nepřidávat do dependency array
  useEffect(() => {
    postForm(data);
  }, [data, isRequiredSet, isShowModalInvalidInput]);

  return {
    setData,
    isSubmited,
    isError,
    isRequiredSet,
    setIsRequired,
    isShowModal,
    setIsShowModal,
    isShowModalInvalidInput,
    setIsShowModalInvalidInput,
    isLoading,
    alertInfo,
    setAlertInfo
  };
}
export default useFormPost;
