import React from "react";
import Contact from "./Contact/Contact";
import { useLocation } from "react-router-dom";
function ContactPage() {
  const path = useLocation()

  if(!(path.pathname === "/")) document.title = `Kontakt | MANOTHERM CZ`
  return (
    <>
      <Contact />
    </>
  );
}
export default ContactPage;
