import React, { useState, useEffect, useRef } from "react";
import Grid from "../Grid/Grid";
import ProductThumb from "../ProductThumb/ProductThumb";
import { useTeplotniSnimaceContext } from "../Context/TeplotniSnimaceContext";
import FilterMenu from "../FilterMenu/FilterMenu";

import {
  Left,
  Right,
  ButtonWrapper,
  Wrapper,
  NotFound,
  ToggleMobileFilters,
  Heading,
  Description
} from "../Manometry/Manometry.styled";
import Spinner from "../Spinner/Spinner";
import Error from "../Error/Error";
import Button from "../Button/Button";

import useTeplotniSnimaceSearchBar from "../SearchBarVisualData/teplotniSnimaceSearchBar";
import useRequireImage from "../../hooks/useRequireImage";

function TeplotniSnimace() {
  const data = useTeplotniSnimaceContext();
  const teplotniSnimace = data.products.products;
  const totalPages = data.products.totalPages;
  const { setPage, isLoading, isError, page } = data;
  const [isMobileFilters, setIsMobileFilters] = useState(false);
  const { searchBarData, reset } = useTeplotniSnimaceSearchBar();
  const tryRequire = useRequireImage()
  const leftRef = useRef(null);

  useEffect(() => {
    if (!isMobileFilters) return;
    function handleClickOutside(event) {
      if (
        leftRef.current &&
        !leftRef.current.contains(event.target) &&
        isMobileFilters
      ) {
        setIsMobileFilters(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileFilters]);

  if (!teplotniSnimace)

    return (
      <>
      
        {isLoading ? <Spinner /> : ""}
        {isError && !isLoading ? (
          <Error
            text={"Při načítání dat nastala chyba, zkuste to prosím později"}
            backTo="produkty"
          />
        ) : (
          ""
        )}
      </>
    );
  return (
    <>
      <Wrapper>
      <Left isMobileFilters={isMobileFilters} ref={leftRef}>
          <FilterMenu
            data={searchBarData}
            reset={reset}
            setPage={setPage}
            isMobileFilters={isMobileFilters}
            setIsMobileFilters={setIsMobileFilters}
          />
        </Left>
        <Right>
          {!teplotniSnimace.length ? (
            <NotFound>
              Vašemu zadání neodpovídají žádné produkty.
              <br /> Nenašli jste provedení produktu, které hledáte? Využijte{" "}
              <b>kontaktního formuláře</b>.{" "}
            </NotFound>
          ) : (
            <>
                   <Heading>Teplotní snímače</Heading>
              <Description>
                Na této stránce naleznete katalog teplotních snímačů. Pro usnadnění
                vyhledávání doporučujeme využití filtrů. Nabízíme na výběr mezi termistorem a termočlánkem.
              </Description>
              <ToggleMobileFilters
                onClick={() => setIsMobileFilters((prev) => !prev)}
              >
                Zobrazit filtry
              </ToggleMobileFilters>
              <Grid
           
                content={teplotniSnimace.map((teplotniSnimac) => {
                  const description = {
                    descriptionOne: teplotniSnimac.supplier_id,
                    descriptionTwo: teplotniSnimac.parametry["Kategorie"],
                 
                  };
                  const image = tryRequire(teplotniSnimac.main_image_url)
                  return (
                    <ProductThumb
                      key={teplotniSnimac._id}
                      itemName={teplotniSnimac.title}
                      imageSrc={image}
                      description={description}
                    />
                  );
                })}
              />
            </>
          )}{" "}
        </Right>
        {page < totalPages ? (
          <ButtonWrapper>
            <Button
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
              text={"Další produkty"}
            />
          </ButtonWrapper>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
}

export default TeplotniSnimace;
