import React from "react";
import useRequireImage from "../hooks/useRequireImage";
import { Paper } from "./Form/Form.styled";
import { Wrapper } from "./InquiryForm/InquiryForm.styled";
import { Image} from "./Productdetail/ProductDetail.styled";
import { TilesWrapper, Tile } from "./Tile/Tile.styled";
import { useLocation } from "react-router-dom";
import { StyledLink } from "./Header/Header.styles";
import imagePath from "../Images/IMG_0209oriz.webp"
function About() {
  const path = useLocation()

  if(!(path.pathname === "/"))document.title = `O nás | MANOTHERM CZ`
  const tryRequire = useRequireImage();
  return (
    <>
      <Wrapper>
        <h1>O nás</h1>
        <Paper>
          <TilesWrapper>
            <Tile>
              <h4>Kdo jsme?</h4>
              <p>
                Jsme česká firma sídlící v Plzni, obchod však realizujeme
                po celé České republice a střední Evropě. Prodejem měřící a regulační techniky
                se zabýváme od roku 1996. Věříme, že i v dnešní době je
                na místě individuální přístup, proto se našim zákazníkům snažíme
                vyjít vstříct v jejich konkrétních požadavcích. Věnujeme se maloobchodu i
                velkoobchodu.
              </p>
              <h4>Co nabízíme?</h4>
              <p>
                Zabýváme se prodejem průmyslové a měřící techniky se zaměřením
                na manometry, teploměry, tlakové senzory a jejich příslušenství.
                V naší nabídce naleznete kvalitní výrobky a služby od výrobců:
                Prematlak (v roce 2015 došlo ke spojení s MANOMER SK), REMART,
                PRESSTEMP, MANTEX, PEVEKO, WIKA, APATOR METRA, ZPA Nová Paka,
                ARMANO, B.D Sensors a další. Sortiment produktů si
                můžete prohlédnout v kategorii produkty, pokud jste zde
                nenalezli hledaný produkt nebo máte zájem o konkrétní typ,
                můžete nás kontaktovat přímo na uvedené kontakty nebo využít
                poptávkového formuláře.
              </p>
              <h4>Jak objednat?</h4>
              <p>
                Veškeré poptávky a objednávky zasílejte na naši emailovou adresu{" "}
                <a href={`mailto:obchod@havlik-manotherm.cz`}>obchod@havlik-manotherm.cz</a>{" "}
                nebo využijte kontaktního formuláře. Následně Vás budeme
                kontaktovat s nabídkou na uvedenou adresu.{" "}
              </p>
              <h4>Certifikováno ISO 9001:2016</h4>
              <p>
                Od roku 2016 jsme každoročně držitelem certifkátu ISO 9001:
                2016 - kvalita managmenetu. <a href= {tryRequire("MANOTHERMCZ_ISO9001.pdf")} target= "_blank" rel = "noopener noreferrer"
                 onClick={()=> window.gtag("event", "isoCertifikate_view")} >
                   Zobrazit certifikát</a>
              </p>
              <h4>Zpracování údajů a cookies</h4>
              <p>
               Všechny potřebné informace se můžete dovězdět pod tímto <StyledLink to = "/ochrana-udaju" color = "#000">odkazem</StyledLink>.
              </p>

            </Tile>
            <Tile
            border_radius="15px 0 0 15px"
            
            >
              <Image
                src={imagePath}
                object_fit="cover"
                border_radius="none"
                alt = "obrázek firmy"
              />
            </Tile>

         
          </TilesWrapper>
        </Paper>
      </Wrapper>
    </>
  );
}
export default About;
