import { useManometryContext } from "../Context/ManometryContext";

function useManometrySearchBar() {
  const { setVelikost, setPripojeni, setRozsah, setTyp, setCategory, setPage, velikost, rozsah, pripojeni, typ, category } =
    useManometryContext();
  function reset() {
    setVelikost(0);
    setRozsah("");
    setTyp("");
    setPripojeni("");
    setPage(0);
    setCategory("")
    return;
  }
  const searchBarData = [
    {
      handler: setTyp,
      value: [
        "mechanical",
        "digital"
      ],
      text: [
        "Mechanické",
        "Digitální"
      ],
      name: "typ",
      title: "Provedení",
      state: typ
    },
    {
      handler: setVelikost,
      value: [25, 40, 50, 63, 80, 100, 160],
      text: ["25 mm", "40 mm", "50 mm", "63 mm", "80 mm", "100 mm", "160 mm"],
      name: "velikost",
      title: "Průměr pouzdra",
      state: velikost
    },
    
    {
      handler: setPripojeni,
      value: ["Spodní", "Zadní", "Kapilární"],
      text: ["Spodní", "Zadní", "Kapilární"],
      name: "pripojeni",
      title: "Připojení",
      state: pripojeni
    },
    {
      handler: setRozsah,
      value: ["podtlak", "nizkotlak", "stredotlak", "stredotlak2", "vysokotlak"],
      text: ["< 0 kPa / 0 bar ", "< 41 kPa / 410 mbar", "< 10,1 MPa / 101 bar","< 40,1 MPa / 401 bar", "> 40,1 MPa / 401 bar"],
      name: "rozsah",
      title: "Rozsah",
      state: rozsah
    },{
      handler: setCategory,
      value: [
        "standard",
        "prumysl",
        "nerez",
        "etalon",
        "kontakt",
        "diference",
        "kolej",
        "ostatni",

      ],
      text: [
        "Standardní",
        "Průmyslové",
        "Nerezové",
        "Etalonové",
        "Kontaktní",
        "Diferenční",
        "Kolejové",
        "Ostatní",
      ],
      name: "category",
      title: "Kategorie",
      state: category
    },
  ];
  return { searchBarData, reset };
}
export default useManometrySearchBar;
