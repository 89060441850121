import React, { useState } from "react";
import {
  Title,
  Accordion,
  Panel,
  Wrapper,
  ButtonWrapper,
  CategoryTitle,
  FilterTitle
} from "./FilterMenu.styled";
import InputCheck from "../InputCheck/InputCheck";
import Button from "../Button/Button";
function FilterMenu({ data, reset, setPage, isMobileFilters, setIsMobileFilters }) {
  const [active, setActive] = useState({});

  if (!data) return;

  function handleClick(id) {
    setActive((prev) => {
      // Create a new object with all keys set to false
      const newActive = {};
      Object.keys(prev).forEach((key) => {
        newActive[key] = false;
      });
      // Set the current id to true
      newActive[id] = !prev[id];
      return newActive;
    });
  }

  return (
    <>
      <Wrapper>
        {isMobileFilters? <Title onClick = {()=> setIsMobileFilters(false)}>Zavřít</Title> : <Title>Dostupné filtry</Title>}
        

        {data.map((item) => {
          let i = 0;
            let index;
          if (item.state) {
            index = item.value.indexOf(item.state);
          }
          return (
            <React.Fragment key={`Key-${item.name}`}>
              <Accordion
                key={item.name}
                onClick={() => handleClick(item.name)}
                active={active[item.name]}
              >
             
                <CategoryTitle>{item.title}</CategoryTitle>
                <FilterTitle>{item.text[index]}</FilterTitle>
                
               
              </Accordion>

              <Panel active={active[item.name]} key={`Panel-${item.name}`}>
                {item.value.map((inputField) => {
                  let text = item.text[i];
                  i++;

                  return (
                    <InputCheck
                      handleClick={item.handler}
                      text={text}
                      value={inputField}
                      type="radio"
                      name={item.name}
                      key={inputField}
                      setPage={setPage}
                      checked={inputField === item.state ? true : false}
                    />
                  );
                })}
              </Panel>
            </React.Fragment>
          );
        })}

        <ButtonWrapper>
          <Button
            type="reset"
            onClick={() => {
              reset();
              setActive((prev) => {
                // Create a new object with all keys set to false
                const newActive = {};
                Object.keys(prev).forEach((key) => {
                  newActive[key] = false;
                });
              
                return newActive;
              });
            }}
            text="Výchozí nastavení"
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
}

export default FilterMenu;
