import { useState, useEffect } from "react";
import ProductsDataService from "../services/productsDataService";
import { isPersistedState } from "../http-common";
let filters = {
  prumer: 0,
  typ: "",
  category: "",
  pripojeni: "",
  page: 0,
};
function useProductsFetch() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prumer, setPrumer] = useState(filters.prumer);
  const [typ, setTyp] = useState(filters.typ);
  const [category, setCategory] = useState(filters.category);
  const [pripojeni, setPripojeni] = useState(filters.pripojeni);
  const [page, setPage] = useState(filters.page);

  async function getProducts(
    prumer = 0,
    typ = "",
    pripojeni = "",
    category = "",
    page = 0
  ) {
    try {
      setIsError(false);
      setIsLoading(true);
      
      const response = await ProductsDataService.getThermomethers(
        prumer,
        typ,
        pripojeni,
        category,
        page
      );

      let totalPages;

      if (
        response.data.totalNumProducts % response.data.entries_per_page ===
        0
      ) {
        totalPages =
          parseInt(
            response.data.totalNumProducts / response.data.entries_per_page,
            10
          ) - 1;
      } else {
        totalPages = parseInt(
          response.data.totalNumProducts / response.data.entries_per_page,
          10
        );
      }

      setProducts((prev) => ({
        ...response.data,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : [...response.data.products],
        totalPages,
      }));
    } catch (err) {
      console.error(`Unable to fetch data in useThermomethersFetch ${err}`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (
      filters.prumer === prumer &&
      filters.category === category &&
      filters.pripojeni === pripojeni &&
      filters.typ === typ &&
      filters.page === page
    ) {
      const sessionState = isPersistedState("Teplomery");
      if (sessionState) {
        setProducts(sessionState);

        return;
      }
    }
    getProducts(prumer, typ,pripojeni , category , page);
  }, [prumer, typ, category, pripojeni, page]);

  useEffect(() => {
    sessionStorage.setItem("Teplomery", JSON.stringify(products));

    filters = { prumer, typ, pripojeni, category, page };
  }, [products, prumer, typ, category, pripojeni, page]);
  //Ořidání prumer a typ do dependency array?
  return {
    products,
    isLoading,
    isError,
    setPrumer,
    setTyp,
    setPage,
    setCategory,
    setPripojeni,
    page,
    prumer,
    typ,
    category,
    pripojeni,
  };
}

export default useProductsFetch;
