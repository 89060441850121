import React from "react"
import {useLoadScript, GoogleMap, Marker} from "@react-google-maps/api"
import {useMemo, useCallback, useRef } from "react"


import Spinner from "../Spinner/Spinner"


 function Map(){

const mapRef = useRef()

    const onLoad = useCallback(map => (mapRef.current = map), [])
const center = useMemo(()=>({lat: 49.710010, lng: 13.415130}),[])
  

const {isLoaded} = useLoadScript({
    googleMapsApiKey: "AIzaSyB2csXkyb5RzVHj3bxEXKUVDbXWOi7gZnE",
    //libraries: ["places"]
})



    if(!isLoaded) return <><Spinner /></>
 
    return(<>
    
       
            <GoogleMap 
            zoom = {14}
            center = {center}
            mapContainerStyle = {{width:"100%", height: "100%"}}
            onLoad = {onLoad}
            >
                <Marker position={center} />

            </GoogleMap>
       


       
    
    
    
    </>)
}


export default Map