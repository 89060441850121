import styled from "styled-components";

export const Button = styled.button`
  background-color: #023;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;

  font-size: var(--fontSmall);
  font-weight: 500;

  line-height: 1.5em;
  list-style: none;
  margin: 0 auto;
  outline: none;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;

  :disabled {
    background-color: hsla(200, 100%, 10%, 0.5);
    cursor: initial;
  }

  :hover {
    background-color: hsla(200, 100%, 10%, 0.5);
  }
`;
