import styled from "styled-components";
import { Link } from "react-router-dom";
import hamburgerImage from "../../Images/icon-hamburger.svg";
import closeImage from "../../Images/icon-close.svg";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.color ? props.color : "#fff"};
`

export const Wrapper = styled.nav`
  background-color: #023;
  padding: 20px 3em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 644px) {
    padding: 10px 3em;
  }

  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: top 0.3s;
  font-size: var(--fontMed);
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5em;

  @media screen and (max-width: 42em) {
    position: fixed;
    inset: 0 0 0 50%;
   
    transform: ${(props) =>
      props.visible ? "translateX(0%)" : "translateX(100%)"};
    transition: transform 600ms ease-out;

    flex-direction: column;
    justify-content: flex-start;
    background: hsl(0 0% 0% / 0.8);
    padding: min(30vh, 10rem) 2em;

  }
`;

export const Menu = styled.button`
  display: none;
  @media screen and (max-width: 42em) {
    display: block;
  }

  z-index: 9999;
  background-image: ${(props) =>
    props.visible ? `url(${closeImage})` : `url(${hamburgerImage})`};
  background-color: transparent;
  background-repeat: no-repeat;
  :hover {
    cursor: pointer;
  }
  width: 2rem;
  aspect-ratio: 1;
  border: 0;

  span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

export const LogoTitle = styled.div`

height: 50px;

.logo {
  height: 50px;
width: 50px;
 
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg) brightness(105%) contrast(101%);
  
}
`;
export const Poptavka = styled.span`


`;
export const PoptavkaSmallScreen = styled.span`
  @media screen and (min-width: 42em) {
    display: none;
  }
`;
