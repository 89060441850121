import React, { useContext } from "react";
import useThermomethersFetch from "../../hooks/useThermomethersFetch";

const TeplomeryContext = React.createContext();

export function useTeplomeryContext() {
  return useContext(TeplomeryContext);
}

export default function TeplomeryProvider({ children }) {
  const productsData = useThermomethersFetch();

  return (
    <TeplomeryContext.Provider value={productsData}>
      {children}
    </TeplomeryContext.Provider>
  );
}
