import styled from "styled-components";
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 1rem;
  text-align: center;

  .center {
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`