import { Wrapper, Content, Text, Certifikat } from "./HomePageImage.styles";
import image from "../../Images/manotherm_pozadi2.webp"
import certifikat from "../../Images/certifikat.webp"

function HomepageImage() {
  return (
    // add image to wrapper later on
    <Wrapper image = {image} alt = "hlavní obrázek">
       <Certifikat src = {certifikat} alt = "certifikát ISO" />
      <Content>
       
        <Text>
          <h1>MANOTHERM CZ</h1>
          <p>
            Prodej měřící a regulační techniky se zaměřením na manometry,
            tlakoměry a jejich příslušenství.
          </p>
        </Text>
      </Content>
    </Wrapper>
  );
}

export default HomepageImage;
