import React from "react";
import PrislusenstviProvider from "./Context/PrislusenstviContext";
import Prislusenstvi from "./Prislusenstvi/Prislusenstvi";
function PrislusenstviPage() {
  document.title = `Příslušenství | MANOTHERM CZ`
  return (
    <>
      <PrislusenstviProvider>
        <Prislusenstvi />
      </PrislusenstviProvider>
    </>
  );
}
export default PrislusenstviPage;
