import React, { useState, useEffect, useRef } from "react";
import Grid from "../Grid/Grid";
import ProductThumb from "../ProductThumb/ProductThumb";
import { useTeplomeryContext } from "../Context/TeplomeryContext";
import FilterMenu from "../FilterMenu/FilterMenu";
import {
  Left,
  Right,
  ButtonWrapper,
  Wrapper,
  NotFound,
  ToggleMobileFilters,
  Heading,
  Description,
} from "../Manometry/Manometry.styled";
import Spinner from "../Spinner/Spinner";
import Error from "../Error/Error";
import Button from "../Button/Button";

import useTeplomerySearchBar from "../SearchBarVisualData/teplomerySearchBarVisual";
import useRequireImage from "../../hooks/useRequireImage";

function Teplomery() {
  const data = useTeplomeryContext();
  const teplomery = data.products.products;
  const totalPages = data.products.totalPages;
  const { setPage, isLoading, isError, page } = data;
  const [isMobileFilters, setIsMobileFilters] = useState(false);
  const { searchBarData, reset } = useTeplomerySearchBar();
  const tryRequire = useRequireImage();
  const leftRef = useRef(null);

  useEffect(() => {
    if (!isMobileFilters) return;
    function handleClickOutside(event) {
      if (
        leftRef.current &&
        !leftRef.current.contains(event.target) &&
        isMobileFilters
      ) {
        setIsMobileFilters(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileFilters]);
  if (!teplomery)
    return (
      <>
        {" "}
        {isLoading ? <Spinner /> : ""}
        {isError && !isLoading ? (
          <Error
            text={"Při načítání dat nastala chyba, zkuste to prosím později"}
            backTo="produkty"
          />
        ) : (
          ""
        )}
      </>
    );
  return (
    <>
      <Wrapper>
        <Left isMobileFilters={isMobileFilters} ref={leftRef}>
          <FilterMenu
            data={searchBarData}
            reset={reset}
            setPage={setPage}
            isMobileFilters={isMobileFilters}
            setIsMobileFilters={setIsMobileFilters}
          />
        </Left>
        <Right>
          {!teplomery.length ? (
            <NotFound>
              Vašemu zadání neodpovídají žádné produkty.
              <br /> Nenašli jste provedení produktu, které hledáte? Využijte{" "}
              <b>kontaktního formuláře</b>.{" "}
            </NotFound>
          ) : (
            <>
              <Heading>Teploměry</Heading>
              <Description>
                Na této stránce naleznete katalog teploměrů. Pro usnadnění
                vyhledávání doporučujeme využití filtrů. Kompatibilita teploměrů s médiem
                se liší dle zvoleného materiálu jímky. Teploměry s mosaznou
                jímkou jsou určeny pro měření teploty vody, vzduchu,
                a dalších médií, které nepůsobí korozivně na
                slitiny mědi. Pro ostatní případy jsou zde nerezové jímky. Pro špatně přístupná místa měření nabízíme teploměry s kapilárou. Teploměry s posuvným hrdlem a variabilní pouzdrem umožňují individuální nastavení pozice teploměru.
              </Description>
              <ToggleMobileFilters
                onClick={() => setIsMobileFilters((prev) => !prev)}
              >
                Zobrazit filtry
              </ToggleMobileFilters>
              <Grid
                content={teplomery.map((teplomer) => {
                  const description = {
                    descriptionOne: teplomer.supplier_id,
                    descriptionTwo: teplomer.parametry.pripojeni,
                  };
                  const image = tryRequire(teplomer.main_image_url);
                  return (
                    <ProductThumb
                      key={teplomer._id}
                      itemName={teplomer.title}
                      imageSrc={image}
                      description={description}
                    />
                  );
                })}
              />
            </>
          )}{" "}
        </Right>

        {page < totalPages ? (
          <ButtonWrapper>
            <Button
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
              text={"Další produkty"}
            />
          </ButtonWrapper>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
}

export default Teplomery;
